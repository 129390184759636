<template>
  <div class="molecule-filters">
    <div class="title-and-info">
      <div class="filters-title-and-button">
        <div class="filters-title">
          <p>
            <AtomIcon icon="filter-solid" :size="26" />
            Filters
            <span v-if="activeFiltersLength > 0" class="number"
              >/ {{ activeFiltersLength }}</span
            >
          </p>
          <AtomButton
            class="rounded-button"
            v-show="activeFiltersLength > 0"
            label="Clear All"
            :on-click="handleClearAllFilters"
          />
        </div>
        <div class="filters-trigger" @click="toggleShowFilters">
          <p v-if="!showFilters">
            Show Filters
            <AtomIcon icon="sort-down" :size="26" />
          </p>
          <p class="inverse-arrow" v-else>
            Hide Filters
            <AtomIcon icon="sort-down" :size="26" />
          </p>
        </div>
      </div>
    </div>
    <div class="filters clearfix row" :class="{ visible: showFilters }">
      <div class="col">
        <div class="row">
          <div
            v-if="Object.keys(buildingOptions).length >= 2"
            class="col col-padding-4px"
          >
            <div class="select-style">
              <AtomSelect
                id="building-select"
                :options="buildingOptions"
                has-label
                label-text="Building"
                :select-props="{
                  search: false,
                  placeholder: 'Select a building',
                }"
                :value="filters.building"
                @change="($event) => handleFilterChange($event, 'building')"
              />
            </div>
          </div>
          <div
            v-if="Object.keys(floorOptions).length >= 1"
            class="col col-padding-4px"
          >
            <div class="select-style">
              <AtomSelect
                id="floor-select"
                :options="floorOptions"
                has-label
                label-text="Floor Info"
                :select-props="{
                  search: false,
                  placeholder: 'Select a floor',
                }"
                :value="filters.floor"
                @change="($event) => handleFilterChange($event, 'floor')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div
            v-if="Object.keys(workspacesOptions).length >= 1"
            class="col col-padding-4px"
          >
            <div class="select-style">
              <AtomSelect
                id="space-select"
                :options="spaceTypeOptions"
                has-label
                label-text="Space Type"
                :select-props="{
                  search: false,
                  placeholder: 'Select space',
                }"
                :value="filters.spaceType"
                @change="($event) => handleFilterChange($event, 'spaceType')"
              />
            </div>
          </div>
          <div
            v-if="Object.keys(workspacesOptions).length >= 1"
            class="col col-padding-4px"
          >
            <div class="select-style">
              <AtomSelect
                id="workspaces-select"
                :options="workspacesOptions"
                has-label
                label-text="Workstations"
                :select-props="{
                  search: false,
                  placeholder: 'Select workspaces',
                }"
                :value="filters.workspaces"
                @change="($event) => handleFilterChange($event, 'workspaces')"
              />
            </div>
          </div>
        </div>
        <!--        <div class="row">-->
        <!--          <div class="col col-padding-4px">-->
        <!--            <MoleculeMultipleOptions-->
        <!--                ref="multipleOpts"-->
        <!--                :options="computedAmenities"-->
        <!--                title="Amenities"-->
        <!--                :value="filters.amenities"-->
        <!--                @input="($event) => handleFilterChange($event, 'amenities')"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="results-info">
      <div>
        <span v-html="`${filteredSpaces.length} results found`"></span>
      </div>
      Scroll Down
    </div>
  </div>
</template>

<script>
import AtomButton from "../../../atoms/common/AtomButton";
import AtomSelect from "../../../atoms/common/AtomSelect";
import AtomIcon from "../../../atoms/common/AtomIcon";

export default {
  name: "MoleculeFilters",
  components: {
    AtomIcon,
    AtomSelect,
    AtomButton,
  },
  data() {
    return {
      showFilters: true,
      activeFilters: [],
      buildingOptions: [],
      floorOptions: [],
      workspacesOptions: [],
      spaceTypeOptions: [],
      filteredSpaces: [],
      selectedAmenities: [],
    };
  },
  computed: {
    activeProjectSlug() {
      return this.$store.state.project.activeProject;
    },
    activeProject() {
      return (
        this.$store.state.base.landlordDetails?.projects.find(
          (p) => p.slug === this.activeProjectSlug
        ) || null
      );
    },
    buildings() {
      return (
        this.$store.state.base.landlordDetails?.buildings?.filter(
          (b) => b.project_id === this.activeProject?.id || -1
        ) || []
      );
    },
    floors() {
      return (
        this.$store.state.base.landlordDetails?.floors?.filter((fl) =>
          this.buildings.some((bl) => bl.id === fl.building_id)
        ) || []
      );
    },
    spaces() {
      // return this.$store.state.base.landlordDetails?.spaces?.filter((sp) => sp.project_id === this.activeProject?.id || -1) || [];
      return (
        this.$store.state.base.landlordDetails?.spaces?.filter(
          (sp) =>
            this.floors.some((fl) => fl.id === sp.floor_id) &&
            [
              "Team office",
              "Management office",
              "Executive office",
              "Open Space",
              "Meeting room",
              "Default office",
            ].includes(sp.space_type.name)
        ) || []
      );
    },
    workspaces() {
      return this.spaces?.map((sp) => ({
        workplaces: sp?.workplaces || 0,
        id: sp.id,
      }));
    },
    filters() {
      return this.$store.state.project?.filters || null;
    },
    computedAmenities() {
      const amenitiesSet = {};
      const allAmenities = [];
      // todo: get amenities from backend.
      this.spaces?.forEach((sp) => {
        sp?.amenities?.forEach(
          (am) =>
            (amenitiesSet[am.id] =
              allAmenities.find((allAm) => allAm.id === am.id).label || {})
        );
      });
      return Object.entries(amenitiesSet).map(([uuid, textValue]) => ({
        uuid,
        textValue,
      }));
    },
    activeFiltersLength() {
      let count = 0;
      for (const type in this.filters) {
        const val = this.filters[type];
        if (
          ![
            "building",
            "floor",
            "workspaces",
            "spaceType",
            "amenities",
          ].includes(type)
        ) {
          continue;
        }
        if (Array.isArray(val)) {
          if (val.length > 0) {
            count++;
          }
        } else {
          if (val !== -1) {
            count++;
          }
        }
      }
      return count;
    },
  },
  watch: {
    activeProjectSlug() {
      this.initFilterOptions();
    },
    buildings() {
      this.initFilterOptions();
    },
    spaces: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.filterSpaces();
        this.$emit("filtered-spaces", val);
      },
    },
    "$store.state.project.filters": {
      deep: true,
      handler: function () {
        this.filterSpaces();
      },
    },
  },
  mounted() {
    this.initFilterOptions();
  },
  methods: {
    changeDefaultFiltersValues() {
      const firstBuilding = this.buildings?.[0] || null;
      const floors =
        this.floors?.filter((fl) => fl.building_id === firstBuilding.id) ||
        null;
      const firstFloor = floors?.[0] || null;
      this.handleFilterChange(firstBuilding?.id || -1, "building");
      this.handleFilterChange(firstFloor?.id || -1, "floor");
    },
    toggleShowFilters() {
      this.showFilters = !this.showFilters;
    },
    initFilterOptions() {
      this.setBuildingOptions();
      this.setFloorOptions();
      this.setWorkspacesOptions();
      this.setSpaceTypeOptions();
      // this.changeDefaultFiltersValues();
    },
    setBuildingOptions() {
      const buildingOptions = [{ text: "All", id: -1 }];
      for (const b of this.buildings) {
        buildingOptions.push({
          text: b.name,
          id: b.id,
        });
      }
      buildingOptions.sort((a, b) => this.compare(a.id, b.id));
      this.buildingOptions = buildingOptions;
    },
    setFloorOptions() {
      let floorOptions = [{ text: "All", id: -1 }];
      for (const f of this.floors) {
        floorOptions.push({
          text: `Floor ${f.no}`,
          id: f.id,
        });
      }
      floorOptions = this.getUniqueListBy(floorOptions, "text");
      floorOptions.sort((a, b) => this.compare(a.text, b.text));
      this.floorOptions = floorOptions;
    },
    setWorkspacesOptions() {
      let workspacesOptions = [{ text: "Any", id: -1 }];
      for (const w of this.workspaces) {
        workspacesOptions.push({
          text: w.workplaces,
          id: w.workplaces,
        });
      }
      workspacesOptions = this.getUniqueListBy(workspacesOptions, "text");
      workspacesOptions.sort((a, b) => this.compare(a.text, b.text));
      this.workspacesOptions = workspacesOptions;
    },
    setSpaceTypeOptions() {
      let spaceTypeOptions = [
        { text: "All", id: -1 },
        {
          text: "Office",
          id: [
            "Team office",
            "Management office",
            "Executive office",
            "Default office",
          ],
        },
        { text: "Meeting Room", id: ["Meeting room"] },
      ];
      spaceTypeOptions = this.getUniqueListBy(spaceTypeOptions, "text");
      spaceTypeOptions.sort((a, b) => this.compare(a.text, b.text));
      this.spaceTypeOptions = spaceTypeOptions;
    },
    compare(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    handleFilterChange(e, type) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_FILTER_STATE
        ),
        {
          value: e,
          type,
        }
      );
    },
    filterSpaces() {
      let filteredSpaces = [...this.spaces];
      if (this.filters.building !== -1) {
        filteredSpaces = filteredSpaces?.filter(
          (s) => Number(s.building_id) === Number(this.filters.building)
        );
      }
      if (this.filters.floor !== -1) {
        filteredSpaces = filteredSpaces?.filter(
          (s) => Number(s.floor_id) === Number(this.filters.floor)
        );
      }
      if (this.filters.workspaces !== -1) {
        filteredSpaces = filteredSpaces?.filter(
          (s) => Number(s?.workplaces || 0) === Number(this.filters.workspaces)
        );
      }
      if (this.filters.spaceType !== -1) {
        filteredSpaces = filteredSpaces?.filter((s) =>
          this.filters.spaceType.includes(s?.space_type.name)
        );
      }
      // if (this.filters.amenities?.length > 0) {
      //   filteredSpaces = filteredSpaces?.filter((s) => {
      //     const result = s.amenities.map(am => am.id).filter(am => this.filters.amenities.includes(am.toString()));
      //     return result.length > 0;
      //   });
      // }
      this.filteredSpaces = filteredSpaces;
      this.$emit("filtered-spaces", filteredSpaces);
    },
    handleClearAllFilters() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.RESET_FILTERS)
      );
      this.initFilterOptions();
    },
  },
};
</script>

<style lang="scss">
.molecule-filters {
  .col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .col-padding-4px {
    padding: 0 0.25rem;
  }
  .col-padding-13px {
    padding: 0 0.813rem;
  }
  .display-inline {
    display: inline-block;
    vertical-align: middle;
  }
  .list-amenities {
    cursor: pointer;
    background: #f5f7f8;
    border-radius: 0.063rem;
    padding: 0.188rem 0.75rem;
    transition: none;
    @include font-main(0.938rem, $black, $regular, 1.125rem);
    .icon {
      font-size: 0.813rem !important;
      margin-right: 0.313rem;
      display: inline-block;
      vertical-align: middle;
      transition: none;
      &.icon-minus-simple {
        font-size: 0.25rem !important;
      }
      &:before {
        margin: 0;
        width: auto;
      }
    }
    &:hover,
    &.active {
      background: $black;
      color: $white;
      .icon {
        color: $white !important;
      }
    }
  }
  .filters-row {
    margin-left: -0.313rem;
    margin-right: -0.313rem;
  }
  .filters-title-and-button {
    margin-bottom: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: 767px) {
      padding: 1rem 0 0;
    }
    .filters-title {
      display: flex;
      align-items: center;
      .rounded-button {
        margin-left: 1.25rem;
        padding: 2px 0.75rem;
        gap: 0.625rem;
        border: none;
        border-radius: 18.75rem;
        background: $darkerWhite;
        transition: all 0.5s ease-in-out;
        span {
          text-transform: none;
          font-weight: $regular;
          color: $black;
        }
        &:hover {
          background: $black;
          span {
            color: $white;
          }
        }
      }
      p {
        @include font-main(1rem, $black, $medium, 1.188rem);
      }
      .icon {
        font-size: 1.125rem !important;
        display: inline-block;
        vertical-align: middle;
        margin-top: -0.063rem;
        margin-right: 0.313rem;
        &:before {
          margin: 0;
        }
      }
    }
    .filters-trigger {
      cursor: pointer;
      p {
        @include font-main(0.875rem, rgba(2, 2, 2, 0.3), $regular, 1.063rem);
        .icon {
          font-size: 1rem !important;
          display: inline-block;
          vertical-align: middle;
          margin-top: -1.563rem;
          margin-left: 0.313rem;
          height: 0;
          &:before {
            margin: 0;
            height: 0;
          }
        }
        &.inverse-arrow {
          .icon {
            transform: rotate(180deg);
            margin-top: 1.25rem;
          }
        }
      }
    }
  }
  .title-filter {
    padding: 0 0 0.5rem;
    margin: 0;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    @include font-main(0.688rem, rgba(2, 2, 2, 0.8), $medium, 0.813rem);
  }
  .btnShowElements {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1rem;
  }
  .border-1px-bottom {
    padding-bottom: 1.563rem;
    border-bottom: 0.063rem solid #e9edef;
    margin-bottom: 0.75rem;
  }
  .m-b-8 {
    margin-bottom: 0.5rem;
  }
  .filters {
    background: $white;
    display: none;
    @media only screen and (max-width: 767px) {
      padding: 1rem 1.25rem;
    }
    &.visible {
      display: block;
    }
    .atom-check-box {
      label {
        z-index: 0;
        font-size: 0.937rem;
        line-height: 1.125rem;
        @media only screen and (max-width: 767px) {
          font-size: 0.875rem;
          line-height: 0.875rem;
        }
      }
      .check-box {
        .icon {
          color: #23cd9a !important;
        }
      }
    }
    .select-style {
      background: #f5f7f8;
      width: 100%;
      border-radius: 0.125rem;
      margin-bottom: 0.5rem;
      label {
        display: block;
        height: auto;
        min-width: 0;
        border: none;
        background: transparent;
        margin: 0;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 0.5rem 0.75rem 0.188rem;
        @include font-main(0.688rem, rgba(0, 0, 0, 0.6), $medium, 0.813rem);
      }
      .select2-container {
        .selection {
          .select2-selection--single {
            padding: 0.188rem 0.75rem 0.625rem;
            font-size: 1rem;
            line-height: 1.188rem;
            .select2-selection__rendered {
              font-size: 1rem;
              line-height: 1.188rem;
            }
            .select2-selection__arrow {
              height: 2rem;
              width: 2rem;
              top: -0.188rem;
              right: 0.375rem;
              b {
                margin-left: 0;
                margin-top: 0;
                transform: translate(-50%, -50%);
                border-color: $black transparent transparent transparent;
                border-width: 0.375rem 0.313rem 0 0.313rem;
              }
            }
          }
        }
      }
      .select2-container--default {
        &.select2-container--open {
          .select2-selection--single {
            .select2-selection__arrow {
              b {
                border-color: transparent transparent $black transparent;
                border-width: 0 0.313rem 0.375rem 0.313rem;
              }
            }
          }
        }
      }
      .select-inline-block {
        display: block;
      }
    }
  }
  .results-info {
    display: flex;
    justify-content: space-between;
    background: black;
    margin: 0 -0.5rem;
    padding: 0.625rem;
    @include font-main(1rem, $white, $regular, 1.125rem);
    @media only screen and (max-width: 767px) {
      div {
        @include font-main(1rem, $white, $regular, 1.125rem);
      }
    }
    .number {
      font-size: 1.1em;
      font-weight: 500;
      @media only screen and (max-width: 767px) {
        font-size: 0.875rem;
        line-height: 1.125rem;
      }
    }
  }
}
</style>
