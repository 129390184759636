<template>
  <div class="organism-space">
    <div v-if="isFloorView" class="general-elements-style">
      <!--      <div-->
      <!--        class="slider-wrapper hidden-mobile-elements active"-->
      <!--        ref="sliderComponent"-->
      <!--      >-->
      <!--        <span class="plus hshsh" @click="increaseZoomValue"-->
      <!--          ><AtomIcon :size="16" icon="zoom-in"-->
      <!--        /></span>-->
      <!--        <div class="slider-parent">-->
      <!--          <div id="slider">-->
      <!--            <i class="line line1"></i>-->
      <!--            <div-->
      <!--              id="dragger"-->
      <!--              ref="dragger"-->
      <!--              class="ui-slider-handle"-->
      <!--              draggable="true"-->
      <!--              style="bottom: 0%"-->
      <!--              @drag="handleDrag"-->
      <!--              @dragstart="handleDragStart"-->
      <!--            >-->
      <!--              ZOOM-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <span class="minus" @click="decreaseZoomValue"-->
      <!--          ><AtomIcon :size="16" icon="zoom-out"-->
      <!--        /></span>-->
      <!--      </div>-->
      <div class="buttons-right active">
        <div
          class="info-button"
          :class="{ active: open }"
          @click="handleInfoTrigger"
        >
          <AtomIcon :icon="open ? 'xmark' : 'info'" :size="20" />
        </div>
        <span class="line-style"></span>
        <div class="buttons-3D">
          <div class="button-tooltip" ref="button2d">
            <span
              :class="{
                'btn-tooltip': true,
                'change-view': true,
                text2D: true,
                iconTooltip: true,
              }"
              @click="changeViewMode2D"
              >2D</span
            >
            <p class="hidden-mobile-elements">Select 2D View Mode</p>
          </div>
          <div class="button-tooltip" ref="button3d">
            <span
              :class="{
                'btn-tooltip': true,
                'change-view': true,
                text3D: true,
                iconTooltip: true,
                active: true,
              }"
              @click="changeViewMode3D"
              >3D</span
            >
            <p class="hidden-mobile-elements">Select 3D View Mode</p>
          </div>
          <div class="button-tooltip" ref="buttonVt">
            <span
              :class="{
                'btn-tooltip': true,
                'change-view': true,
                walkThrough: true,
                iconTooltip: true,
              }"
              @click="changeViewModeFirstPerson"
              ><AtomIcon icon="street-view" :size="20"
            /></span>
            <p class="hidden-mobile-elements">Enter Virtual Tour</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../../atoms/common/AtomIcon";
import { CurrentView, FloorViewMode } from "./store";
import { BabylonInitializeHelper } from "./store/helpers";
import { BabylonSceneManager } from "@/components/organisms/project/building/store/3d/managers/SceneManager";

const isMobile = true;

export default {
  name: "OrganismSpace",
  components: { AtomIcon },
  props: {
    matterportSpace: {
      required: true,
      type: Object | Array,
      default: function () {
        return {
          titleSpace: "",
          subTitleSpace: "",
        };
      },
    },
  },
  data() {
    return {
      viewModes: FloorViewMode,
      zoomSettings: {
        zoomValue: 0,
        minValue: 0,
        maxValue: 45,
      },
      fitoutData: {
        fitoutOptions: [],
        fitouts: {},
      },
      isSpaceSelectionOpen: false,
      showCartSpacesMobile: !isMobile,
      activeTab: "matterport",
    };
  },
  computed: {
    babylonHelper() {
      return BabylonInitializeHelper;
    },
    hasMatterport() {
      return this.$store.state.base.meta.general.matterportEnabled;
    },
    buildingStore() {
      if (!this.$parent.$refs.building) return { state: {} };
      return this.$parent.$refs.building.buildingStore;
    },
    floorInteractions() {
      return this.babylonHelper.floorInteractionHelper();
    },
    virtualTour() {
      return this.babylonHelper.virtualTourHelper();
    },
    isFloorView() {
      return this.buildingStore.state.currentView === CurrentView.FLOOR;
    },
    viewMode() {
      return this.buildingStore.state.floorViewMode;
    },
    currentSpace() {
      return this.buildingStore?.state?.space?.spaceData || {};
    },
    open() {
      return this.$store.state?.project?.infoBox?.open || false;
    },
    spaceBuilding() {
      return this.$store.state.project.project.buildings.find(
        (b) => b.id === this.currentSpace.building_id
      );
    },
    spaceFloor() {
      return this.$store.state.project.project.floors.find(
        (f) => f.id === this.currentSpace.floor_id
      );
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    isDualLevel() {
      return this.currentSpace.is_dual_level;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    gallery() {
      return this.$store.state.space.gallery;
    },
    photos() {
      return JSON.parse(JSON.stringify(this.$store.state.space.photos));
    },
    videos() {
      return JSON.parse(JSON.stringify(this.$store.state.space.videos));
    },
    landlordDetails() {
      return this.$store.state.base.landlordDetails;
    },
  },
  async beforeMount() {
    const spaceId =
      this.$route.params.spaceId ||
      this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot;
    if (!spaceId) return;
    const spaceConstants = this.$store.getters.constants.space;
    await this.$store.dispatch(
      spaceConstants.withNamespace(spaceConstants.action.LOAD_SPACE_DETAILS),
      spaceId
    );
    this.sceneManager = BabylonSceneManager;
  },
  mounted() {
    this.buildingStore.subscribe((payload, state) => {
      const { fitoutData } = state;
      if (fitoutData.data) {
        this.fitoutData = fitoutData.data;
      }
    });
  },
  watch: {
    currentSpace(value, old) {
      if (!value.matterport_url) {
        this.activeTab = "floor-view";
      }
    },
    viewMode(value, old) {
      const getSiblings = function (elem) {
        return Array.prototype.filter.call(
          elem.parentNode.children,
          function (sibling) {
            return sibling !== elem;
          }
        );
      };
      if (value !== old) {
        switch (value) {
          case this.viewModes.FIRST_PERSON:
            this.$refs.buttonVt.querySelector("span").classList.add("active");
            getSiblings(this.$refs.buttonVt).forEach((sibling) => {
              sibling.querySelector("span").classList.remove("active");
            });
            // if (this.$refs.buttonG) {
            //   this.$refs.buttonG.querySelector("span").classList.add("active");
            //   getSiblings(this.$refs.buttonG).forEach((sibling) => {
            //     sibling.querySelector("span").classList.remove("active");
            //   });
            //   this.changeLevel("G", event);
            // }
            // this.$refs.sliderComponent.classList.remove("active");
            // if (this.$refs.levelComponent) {
            //   this.$refs.levelComponent.classList.add("active");
            // }
            break;
          case this.viewModes.TWO_DIMENSIONAL:
            this.$refs.button2d.querySelector("span").classList.add("active");
            getSiblings(this.$refs.button2d).forEach((sibling) => {
              sibling.querySelector("span").classList.remove("active");
              // this.$refs.sliderComponent.classList.add("active");
              // if (this.$refs.levelComponent) {
              //   this.$refs.levelComponent.classList.remove("active");
              // }
            });
            break;
          case this.viewModes.DEFAULT:
            this.$refs.button3d.querySelector("span").classList.add("active");
            getSiblings(this.$refs.button3d).forEach((sibling) => {
              sibling.querySelector("span").classList.remove("active");
              // this.$refs.sliderComponent.classList.add("active");
              // if (this.$refs.levelComponent) {
              //   this.$refs.levelComponent.classList.remove("active");
              // }
            });
            break;
        }
      }
    },
    isSpaceSelectionOpen(value) {
      if (value) {
        this.$refs.listNode.classList.add("active");
        this.$refs.hideText.style.display = "block";
        this.$refs.requestOfferText.style.display = "none";
      } else {
        this.$refs.listNode.classList.remove("active");
        this.$refs.requestOfferText.style.display = "block";
        this.$refs.hideText.style.display = "none";
      }
    },
  },
  methods: {
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) end = dc.length;
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
    async download(url, filename) {
      const projectConstants = this.$store.getters.constants.project;
      const { result, error } = await this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.DOWNLOAD_FILE),
        {
          id: this.currentSpace.id,
          project_slug: this.activeProject.slug,
        }
      );

      if (error) {
        console.log(error);
      } else {
        let link = document.createElement("a");
        link.setAttribute("id", "download-link");
        link.setAttribute("href", result);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    exploreSpace(spaceCode, buildingCode, floorCode, floorTypeCode, space) {
      const routePageData = this.$route.meta.page;
      const resolvedRoute = this.$router.resolve({
        name: routePageData.isEmbedReady
          ? "SpecificSpaceEmbed"
          : "SpecificSpace",
        params: {
          projectId: this.activeProject,
          spaceId: space.id,
        },
      });
      window.location.href = resolvedRoute.href;
    },
    getCombinedSpace(id) {
      if (!this.spaces) {
        return {
          floor: {},
        };
      }
      const foundSpace = this.spaces.find((s) => s.id === id);
      if (!foundSpace) {
        return null;
      }
      foundSpace.floor = this.floors.find((f) => f.id === foundSpace.floor_id);
      return foundSpace;
    },
    changeFitout(value) {
      const fitSpace = value.substring(value.indexOf("s"));
      this.floorInteractions.highlightHelper.showFitputPins(fitSpace);

      this.floorInteractions.loadFitout(value);
      this.virtualTour && this.virtualTour.updateMinimap();
    },
    changeViewMode2D(evt) {
      if (this.viewMode === FloorViewMode.TWO_DIMENSIONAL) return;
      this.buildingStore.dispatch(
        "changeViewMode",
        FloorViewMode.TWO_DIMENSIONAL
      );
      this.sceneManager.switchCamera(FloorViewMode.TWO_DIMENSIONAL);
    },
    changeViewMode3D(evt) {
      if (this.viewMode === FloorViewMode.DEFAULT) return;
      this.buildingStore.dispatch("changeViewMode", FloorViewMode.DEFAULT);
      this.sceneManager.switchCamera(FloorViewMode.DEFAULT);
    },
    changeViewModeFirstPerson(evt) {
      if (this.viewMode === FloorViewMode.FIRST_PERSON) return;
      this.buildingStore.dispatch("changeViewMode", FloorViewMode.FIRST_PERSON);
      this.sceneManager.switchCamera(FloorViewMode.FIRST_PERSON);
    },
    resetPointer() {
      this.sceneManager.resetPointer();
    },
    changeLevel(level, event) {
      this.babylonHelper.virtualTourHelper().updateStartingPoint(level);
      if (!event.target.classList.contains("icon-street-view")) {
        event.target
          .closest("#btn-left-container")
          .querySelectorAll(".active")
          .forEach((item) => {
            item.classList.remove("active");
          });
        event.target.classList.add("active");
      }
    },

    handleDrag(e) {
      if (e.target.id !== "dragger") {
        return;
      }
      const parentRect = e.target.parentElement.getBoundingClientRect();
      const zoomRect = e.target.getBoundingClientRect();
      const cursorVal =
        e.pageY !== 0 ? parentRect.bottom - e.pageY - zoomRect.height / 2 : 0;
      if (
        cursorVal > 0 &&
        cursorVal <= this.zoomSettings.maxValue - zoomRect.height / 2 &&
        cursorVal >= this.zoomSettings.minValue
      ) {
        this.zoomSettings.zoomValue = cursorVal;
        const percentVal = (cursorVal * 100) / this.zoomSettings.maxValue;
        this.$refs.dragger.style.bottom =
          percentVal > 5 ? `${percentVal}%` : `${0}%`;
        this.sceneManager.setZoomValue(this.zoomSettings.zoomValue);
      }
    },
    handleDragStart(e) {
      if (e.target.id !== "dragger") {
        return;
      }
      e.dataTransfer.setDragImage(
        e.target,
        window.outerWidth + 100,
        window.outerHeight + 100
      );
    },
    increaseZoomValue() {
      if (this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 5) {
        this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 10
          ? (this.zoomSettings.zoomValue += 5)
          : (this.zoomSettings.zoomValue = this.zoomSettings.maxValue - 5);
        this.$refs.dragger.style.bottom = `${
          (this.zoomSettings.zoomValue * 100) / this.zoomSettings.maxValue
        }%`;
        this.sceneManager.setZoomValue(this.zoomSettings.zoomValue);
      }
    },
    decreaseZoomValue() {
      if (this.zoomSettings.zoomValue > this.zoomSettings.minValue) {
        this.zoomSettings.zoomValue > 5
          ? (this.zoomSettings.zoomValue -= 5)
          : (this.zoomSettings.zoomValue = 0);
        this.$refs.dragger.style.bottom = `${
          (this.zoomSettings.zoomValue * 100) / this.zoomSettings.maxValue
        }%`;
        this.sceneManager.setZoomValue(this.zoomSettings.zoomValue);
      }
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return;
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        { showModal: true, modalType: "request-offer" }
      );
      document.body.classList.add("disable-scroll");
    },
    toggleSpaceSelectionDetails() {
      this.isSpaceSelectionOpen = !this.isSpaceSelectionOpen;
      this.showCartSpacesMobile = !this.showCartSpacesMobile;
    },
    closeSpaceSelectionDetails() {
      this.isSpaceSelectionOpen = false;
      this.showCartSpacesMobile = false;
    },
    addCurrentSpaceToCart() {
      const space = {
        ...this.currentSpace,
        building: this.spaceBuilding,
        floor: this.spaceFloor,
      };
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        space
      );
    },
    removeSpaceFromCart(space) {
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE),
        space
      );
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId);
    },
    handleTabChange(tabId) {
      this.activeTab = tabId;
    },
    handleInfoTrigger() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.TOGGLE_INFO_BOX),
        {
          open: !this.open,
        }
      );
    },
  },
};
</script>

<style lang="scss">
.organism-space {
  #btn-left-container {
    right: auto;
    left: -5rem;

    &.active {
      left: 0.625rem;

      @media only screen and (max-width: 1200px) {
        left: 2.625rem;
      }
    }

    .button-tooltip {
      @media only screen and (max-width: 1200px) {
        left: auto;
        display: block;
        margin-right: 0;
      }

      .btn-tooltip + p {
        right: auto;
        left: 100%;
        margin-right: auto;
        margin-left: 1.25rem;
      }
    }
  }
  .space-combined {
    position: absolute;
    left: 5rem;
    bottom: -18.75rem;
    z-index: 9;
    padding: 0.625rem 1rem;
    background: $white;
    width: 15.625rem;
    border-radius: 0.25rem;
    text-align: center;
    transition: all 0.5s ease-in-out 0s;
    &.active {
      bottom: 0.625rem;
    }
    p {
      margin-bottom: 0.625rem;
      @include font-main(0.875rem, #272626, $regular, 1.063rem);
    }
    a {
      width: 100%;
    }
  }
  .section-spaces.canvas-buttons-wrapper {
    .background-gray {
      padding: 0;
    }
    .select-style {
      select {
        width: 20rem;
        font-size: 0.75rem;
      }
      label {
        height: 4.125rem;
        background: #e9ecf0;
        padding: 0.313rem 0.625rem;
        border: none;
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        max-width: 100%;
        border-radius: 0.25rem 0 0 0.25rem;
        cursor: pointer;
        margin-top: 0;
        margin-right: -0.125rem;
        @include font-main(0.875rem, $black, $regular, 4.125rem);
      }
      .select-atom-wrapper {
        label {
          display: none;
        }
      }
    }
    .select-inline-block {
      position: relative;
      display: inline-block;
      vertical-align: top;
    }
    .info-fit-out {
      .fit-out-text {
        position: absolute;
        left: 0.75rem;
        top: 0.75rem;
        transition: all 0.2s ease-in-out 0s;
        @include font-main(0.875rem, #5f5f5f, $regular, 1rem);
      }
      .select-text {
        position: absolute;
        right: 1.875rem;
        top: 0.75rem;
        transition: all 0.2s ease-in-out 0s;
        @include font-main(0.875rem, #aaaaaa, $regular, 1rem);
      }
      .info-icon {
        position: absolute;
        bottom: 0.625rem;
        right: 0.75rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out 0s;
        &:hover,
        &.active {
          color: $main-color;
        }
      }
    }
    .select2-container {
      min-width: 20rem;
      .select2-selection--single {
        width: 20rem;
        font-size: 0.75rem;
        height: 4.125rem;
        padding: 0.75rem 2.813rem 0.75rem 0.75rem;
        border-radius: 0 0.25rem 0.25rem 0;
        border: none;
        background: $white;
        .select2-selection__rendered {
          text-transform: initial;
          padding-top: 1.25rem;
          transition: all 0.2s ease-in-out 0s;
          @include font-main(0.875rem, $black, $medium, 1.75rem);
          @media only screen and (max-width: 1200px) {
            font-size: 0.813rem;
          }
        }
        .select2-selection__arrow {
          height: 2.313rem;
          margin-right: 0.313rem;
          b {
            border-width: 0 0.313rem 0.438rem 0.313rem;
            border-color: transparent transparent rgba(9, 9, 9, 0.4) transparent;
          }
        }
      }
      &:hover {
        .select2-selection--single {
          background: #eaeaea;
          .select2-selection__arrow {
            b {
              border-color: transparent transparent #4cc66d transparent;
            }
          }
        }
      }
      &.select2-container--open,
      &.select2-container--open:hover {
        .select2-selection--single {
          background: $black;
          .select2-selection__rendered {
            color: $white;
          }
          .select2-selection__arrow {
            b {
              border-color: $white transparent transparent transparent;
              border-width: 0.438rem 0.313rem 0 0.313rem;
            }
          }
        }
        & + .info-fit-out {
          .fit-out-text {
            color: rgba(255, 255, 255, 0.6);
          }
          .select-text {
            color: rgba(255, 255, 255, 0.4);
          }
          .info-icon {
            color: $white;
          }
        }
      }
    }
  }
  .general-elements-style {
    .slider-wrapper {
      position: absolute;
      left: -4.375rem;
      top: 50%;
      z-index: 9;
      transition: all 0.5s ease-in-out 0s;
      transform: translateY(-50%);
      &.active {
        left: 0.625rem !important;
      }
      & > span {
        font-size: 1rem;
        position: absolute;
        left: 50%;
        z-index: 9;
        cursor: pointer;
        transform: translateX(-50%);
        transition: all 0.2s ease-in-out 0s;
        .icon {
          color: #b4c5d6 !important;
        }
        &.plus {
          top: 0.5rem;
        }
        &.minus {
          bottom: 0.5rem;
        }
        &:hover {
          .icon {
            color: $black !important;
          }
        }
      }
      .slider-parent {
        position: relative;
        width: 2.063rem;
        background: $white;
        padding: 2.375rem 0;
        border-radius: 0.375rem;
        #slider {
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          i {
            display: block;
            width: 0.125rem;
            margin: 0 auto;
            height: 4.125rem;
            background: #d8d8d8;
            transition: all 0.2s ease-in-out 0s;
          }
          .ui-slider-handle {
            display: block;
            position: absolute;
            left: 50%;
            z-index: 9;
            overflow: hidden;
            background: $black;
            width: 0.688rem;
            height: 0.688rem;
            border-radius: 0.688rem;
            text-align: center;
            cursor: pointer;
            transform: translateX(-50%);
            transition: width 0.2s ease-in-out 0s,
              border-radius 0.2s ease-in-out 0s, color 0.5s ease-in-out 0s;
            @include font-main(0.5rem, $black, $bold, 0.688rem);
          }
        }
      }
      &:hover {
        .slider-parent {
          #slider {
            i {
              width: 0.25rem;
            }
            .ui-slider-handle {
              width: 1.938rem;
              border-radius: 0.125rem;
              color: $white;
            }
          }
        }
      }
    }
    .buttons-right {
      position: absolute;
      left: -5.313rem;
      top: 50%;
      background: $white;
      border-radius: 6.25rem;
      padding: 0.25rem;
      z-index: 9;
      transition: all 0.5s ease-in-out 0s;
      transform: translateY(-50%);
      @media only screen and (max-width: 767px) {
        bottom: 7.938rem;
        left: 50%;
        top: initial;
        transform: translateX(-50%);
        width: 16.375rem;
        max-width: 95%;
        text-align: center;
        padding: 0.5rem;
        border-radius: 6.25rem;
      }
      &.active {
        left: 1.25rem;
        @media only screen and (max-width: 767px) {
          left: 50%;
        }
      }
      .info-button {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
        text-align: center;
        background: $white;
        cursor: pointer;
        @include font-main(1.125rem, $black, $bold, 2.5rem);
        @include prefix(
          transition,
          all 0.2s ease-in-out 0s,
          ("webkit", "moz", "ms", "o")
        );
        @media only screen and (max-width: 767px) {
          display: inline-block;
          vertical-align: middle;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 2.5rem;
          @include font-main(1.125rem, $black, $bold, 2.5rem);
        }
        .icon {
          font-size: 1.25rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 1.25rem !important;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
        &.active {
          background: $black;
          color: $white;
          .icon {
            color: $white !important;
          }
        }
      }
      .buttons-3D {
        @media only screen and (max-width: 767px) {
          display: inline-block;
          vertical-align: middle;
        }
        .button-tooltip {
          @media only screen and (max-width: 767px) {
            display: inline-block;
            vertical-align: middle;
            margin: 0 0.313rem;
          }
        }
      }
      .line-style {
        width: 1.5rem;
        height: 0.125rem;
        display: block;
        margin: 0.438rem auto;
        background: #e5eaed;
        @media only screen and (max-width: 767px) {
          display: inline-block;
          vertical-align: middle;
          width: 0.125rem;
          height: 1.5rem;
          margin: 0 0.625rem;
        }
      }
    }
    .saveFitoutSection {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -6.25rem;
      transition: all 0.5s ease-in-out 0s;
      &.active {
        top: 0;
      }
      .newButtonsFitout {
        margin-top: 1.25rem;
        display: inline-block;
        vertical-align: top;
        .btnText {
          display: inline-block;
          vertical-align: top;
          .btnShareFitout,
          .btnShareFitoutText,
          .btnDropdownOlderFitouts,
          .btnDropwdownText,
          .btnRevertFitoutVersion,
          .btnRevertFitoutVersionText {
            margin-left: 0.938rem;
          }
          p {
            margin-top: 0.313rem;
            text-align: center;
            max-width: 2.5rem;
            transition: all 0.2s ease-in-out 0s;
            @include font-main(0.5rem, $black, $bold, 0.75rem);
          }
          .btnDropdownOlderFitouts {
            position: relative;
            display: inline-block;
          }
          .dropdownContent {
            display: none;
            position: absolute;
            background: $white;
            min-width: 15.625rem;
            margin-top: 0.313rem;
            box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
            border-radius: 0.5rem;
            z-index: 1;
            overflow-y: scroll;
            max-height: 15.625rem;
            a,
            span {
              display: block;
              padding: 0.75rem 1rem;
              text-decoration: none;
              @include font-main(0.75rem, rgba(0, 0, 0, 0.6), $regular, 1rem);
            }
            a:hover,
            span:hover {
              background: #eeeeee;
            }
            span {
              text-align: center;
            }
          }
          .dropdownContent::-webkit-scrollbar-track {
            padding: 0.125rem 0;
          }
          .dropdownContent::-webkit-scrollbar {
            width: 0.25rem;
          }
          .dropdownContent::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
            background-color: $main-color;
          }
          .btnRevertFitoutVersion {
            .svg-inline--fa {
              color: $red;
            }
          }
          .btnRevertFitoutVersion:hover {
            background: $red;
            .svg-inline--fa {
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
      .inputStyle {
        margin: 1.25rem;
        .label {
          margin-bottom: 0.438rem;
          @include font-main(0.875rem, rgba(0, 0, 0, 0.6), $regular, 1.188rem);
        }
        .inputFocus {
          input {
            width: 100%;
            padding: 0 1rem;
            color: $black;
            background: $white;
            border: 0.125rem solid $main-color;
          }
        }
      }
    }
    .modalOverlay {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 999998;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .popupConfirm {
      display: none;
      position: fixed;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 50%;
      z-index: 999999;
      background: $blackOpacity09;
      overflow: hidden;
      padding: 2.188rem 0.938rem;
      .btnClosePopup {
        position: absolute;
        top: 5%;
        right: 2%;
        color: $white;
        cursor: pointer;
        font-weight: $bold;
      }
      .titleSubtitle {
        width: 100%;
        height: 70%;
        overflow: hidden;
        @include font-main(1rem, $white, $regular, 1.188rem);
      }
      h2 {
        margin-bottom: 1.25rem;
        @include font-main(1.25rem, $white, $bold, 1.188rem);
      }
      .contentWrapper {
        margin-bottom: 1.25rem;
      }
      .rightButtons {
        bottom: 5%;
        text-align: end;
      }
    }
    .popupScreen {
      .titleEmail {
        margin-top: 1.875rem;
        margin-bottom: 1.25rem;
        .title {
          color: $white;
          display: inline-block;
          vertical-align: middle;
          margin: 0 1.25rem 0 0;
        }
        .btn {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .removeInputsEmail {
        position: absolute;
        right: -0.5rem;
        top: -0.5rem;
        cursor: pointer;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
        border: 0.125rem solid #f20303;
        background: #f20303;
        border-radius: 1.25rem;
        @include font-main(0.875rem, $white, $bold, 0.938rem);
      }
      .linkDiv {
        span {
          @include font-main(0.875rem, $white, $regular, 1.188rem);
        }

        .clipboard {
          color: white;
          right: 1%;
          top: 16%;
          position: absolute;
        }
      }
    }
    .canvas-buttons-wrapper {
      .infoScreen {
        display: none;
      }
    }
    .custom-tooltip {
      position: relative;
      margin-left: 0.313rem;
      text-align: left;
      display: none;
      .btnBorder {
        margin: 0;
      }
    }
    .legends-space {
      position: absolute;
      right: 0;
      bottom: 100%;
      width: 22.25rem;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      z-index: -1;
      &.activeHover {
        right: 100%;
        margin-right: 0.5rem;
        .closeCustomTooltip {
          display: none !important;
        }
        h6 {
          text-align: center !important;
        }
      }
      .fitoutLegend {
        display: none;
        background: $white;
        padding: 1.25rem;
        h6 {
          letter-spacing: -0.021rem;
          margin-bottom: 0.75rem;
          @include font-main(0.875rem, #9a9a9a, $regular, 1.063rem);
        }
        h5 {
          text-align: center;
          padding: 0.5rem 0.625rem;
          background: #f7f7f7;
          border-radius: 0.25rem;
          margin-bottom: 1rem;
          @include font-main(0.875rem, $black, $medium, 1.063rem);
        }
        .closeCustomTooltip {
          cursor: pointer;
          position: absolute;
          right: 1.25rem;
          top: 1rem;
          font-size: 1.25rem;
          transition: all 0.2s ease-in-out 0s;
          &:hover {
            color: $main-color;
          }
        }
        li {
          clear: both;
          display: flex;
          margin-bottom: 0.75rem;
          &:last-child {
            margin-bottom: 0;
          }
          .width60 {
            text-transform: capitalize;
            float: left;
            width: 60%;
            padding-right: 0.313rem;
            @include font-main(0.875rem, #a7a7a7, $regular, 1.063rem);
          }
          .width40 {
            float: left;
            width: 40%;
            padding-left: 0.313rem;
            @include font-main(0.875rem, $black, $medium, 1.063rem);
          }
          b {
            color: $black;
            font-weight: $medium;
          }
        }
        .arrowCustom {
          width: 1.25rem;
          height: 0.688rem;
          right: 0.563rem;
          top: 101%;
          i {
            border-color: $white transparent transparent transparent;
            border-width: 0.688rem 0.625rem 0 0.625rem;
            width: 1.25rem;
          }
        }
      }
    }
    .annotationsSidebar {
      position: absolute;
      left: -15.625rem;
      top: 6.25rem;
      z-index: 8;
      padding: 0.938rem 0;
      background: $white;
      width: 15.625rem;
      border-radius: 0.188rem;
      box-shadow: 0 0 0.625rem $blackOpacity012;
      max-height: 31vh;
      overflow: auto;
      transition: all 0.5s ease-in-out 0s;
      &.active {
        left: 3.75rem;
      }
      .sidebarTitle {
        display: none;
      }
      ul {
        margin-bottom: 0.625rem;
      }
      .arrowCustom {
        display: block;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        i {
          border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent;
          transition: all 0.2s ease-in-out 0s;
        }
      }
      h5 {
        position: relative;
        margin-bottom: 0.5rem;
        padding: 0 0.938rem;
        transition: all 0.2s ease-in-out 0s;
        @include font-main(0.75rem, $black, $bold, 0.875rem);
      }
      li {
        cursor: pointer;
        position: relative;
        padding: 0.313rem 0.938rem;
        transition: all 0.2s ease-in-out 0s;
        @include font-main(0.688rem, rgba(0, 0, 0, 0.6), $regular, 0.938rem);
        &:hover,
        &.active {
          background: $main-color;
          color: $white;
        }
        span {
          display: inline-block;
          vertical-align: middle;
        }
        .width80 {
          width: 70%;
        }
        .width20 {
          width: 30%;
        }
        b {
          display: inline-block;
          vertical-align: middle;
          @include font-main(0.625rem, #282d32, $semiBold, 0.875rem);
        }
      }
      &.activeSlide {
        h5 {
          color: $main-color;
          margin-bottom: 0.625rem;
        }
        .arrowCustom {
          i {
            border-color: transparent transparent $main-color transparent !important;
            border-width: 0 0.313rem 0.375rem 0.25rem;
          }
        }
      }
    }
    .vtPoints {
      position: absolute;
      bottom: 3.438rem;
      left: -6.25rem;
      transition: all 0.5s ease-in-out 0s;
      @media only screen and (max-width: 1200px) {
        bottom: initial;
        top: 3.438rem;
        right: initial;
        left: -6.25rem;
      }
      &.active {
        left: 3.75rem;
        @media only screen and (max-width: 1200px) {
          left: 0.938rem;
        }
      }
      .inline-element {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.938rem;
        @media only screen and (max-width: 1200px) {
          display: block;
          margin: 0 0 0.5rem;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .custom-tooltip {
        margin-left: 0;
        display: block;
        span {
          font-size: 1.625rem;
          font-weight: $bold;
          @media only screen and (max-width: 1200px) {
            font-size: 1.25rem;
          }
        }
      }
      .hideElement {
        display: none;
        @media only screen and (max-width: 1200px) {
          width: 15.625rem !important;
          bottom: initial;
          left: 100%;
          margin: 0 0 0 1.125rem;
          top: 50%;
          transform: translateY(-50%);

          .arrowCustom {
            top: 50%;
            left: -1.063rem;
            @include prefix(
              transform,
              translateY(-50%) rotate(90deg),
              ("webkit", "moz", "ms", "o")
            );
          }
        }
      }
    }
  }
  .canvasBox {
    position: relative;
    background-color: #f7f7f7;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  .mapFirstPerson {
    display: none !important;
    width: 18.75rem;
    position: absolute;
    top: 5.125rem;
    right: 1.25rem;
    border-radius: 0.625rem;
    opacity: 0.5;
    overflow: hidden;
    transition: all 0.5s ease-in-out 0s;
    &:hover {
      opacity: 1;
    }
    svg {
      display: block;
    }
    .circlePin {
      display: block;
      position: absolute;
      width: 0.625rem;
      height: 0.625rem;
      background: $main-color;
      border: 0.063rem solid $main-color;
      border-radius: 0.625rem;
      cursor: pointer;
      margin-top: -0.313rem;
      margin-left: -0.313rem;
      z-index: 4;
      //include prefix(transition, all 0.4s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
      transition: top 0.7s, left 0.7s;
      &:after {
        content: "";
        display: block;
        background: $main-color;
        opacity: 0;
        width: 0.188rem;
        height: 0.188rem;
        border-radius: 0.188rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
      &.active {
        background: rgba(255, 255, 255, 0.8);
        &:after {
          opacity: 1;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 3.188rem;
          height: 3.125rem;
          position: absolute;
          background-image: url("../../../../assets/images/cone.svg");
          transform: rotate(55deg);
          top: 0.75rem;
          left: -1.563rem;
          z-index: 1;
          pointer-events: none;
        }
      }
    }
    .pointerCircle {
      transition: none;
      z-index: 4;
      display: block;
      position: absolute;
      width: 0.625rem;
      height: 0.625rem;
      background: $main-color;
      border: 0.063rem solid $main-color;
      border-radius: 0.625rem;
      cursor: pointer;
      margin-top: -0.313rem;
      margin-left: -0.313rem;
      pointer-events: none;
    }
  }
  .overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0.2;
    z-index: 10;
  }
  #leftFloors {
    position: absolute;
    left: -14.375rem;
    top: 0;
    height: 100%;
    z-index: 99;
    transition: all 0.5s ease-in-out 0s;
    .asideInfoTitle {
      color: #707070;
      display: block;
      margin-bottom: 0.938rem;
      @include font-main(0.875rem, $white, $regular, 1.5rem);
    }
    .sidebarRight {
      position: absolute;
      right: -6.875rem;
      top: 0.5rem;
      z-index: 9;
      .floorVisible {
        display: block;
        position: absolute;
        top: 1.5rem;
        left: 2.375rem;
        text-align: center;
        @include font-main(0.625rem, $black, $bold, 1.125rem);
        #floor-number {
          letter-spacing: -0.03rem;
          display: block;
          @include font-main(0.75rem, $black, $bold, 1.313rem);
        }
      }
      .changeFloor {
        position: absolute;
        bottom: 6.938rem;
        left: -0.313rem;
        letter-spacing: 0.038rem;
        @include font-main(0.625rem, rgba(0, 0, 0, 0.6), $regular, 1.125rem);
        @include prefix(
          transform,
          rotate(-90deg),
          ("webkit", "moz", "ms", "o")
        );
      }
      .seeMoreCircles {
        position: absolute;
        left: 1.75rem;
        bottom: 2.188rem;
        height: 0.563rem;
        line-height: 0.5rem;
        li {
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.063rem;
          .circle {
            width: 0.156rem;
            height: 0.5rem;
            border-radius: 0;
          }
        }
      }
    }
    .positionRelative {
      background: $white;
      height: calc(100% - 2.5rem);
      top: 0.938rem;
      position: relative;
      border: 0.063rem solid #dedede;
      border-left: none;
      background: #f7f7f7;
      padding: 1.25rem 1.875rem;
      border-radius: 0 0 0.625rem 0;
      .floorsButtons {
        ul {
          max-width: 12.813rem;
          overflow: hidden;
          overflow-y: auto;
          height: 42.188rem;
          padding: 0.313rem;
          margin-left: -0.313rem;
        }
        li {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0.188rem;
        }
        .btnReg {
          width: 3.75rem;
        }
      }
    }
  }
  #rightFloors {
    position: absolute;
    bottom: 0;
    right: -14.375rem;
    height: 100%;
    z-index: 99;
    transition: all 0.5s ease-in-out 0s;
    .asideInfoTitle {
      color: #707070;
      display: block;
      margin-bottom: 0.938rem;
      @include font-main(0.875rem, $white, $regular, 1.5rem);
    }
    .sidebarLeft {
      position: absolute;
      left: -6.813rem;
      bottom: 0.625rem;
      z-index: 9;
      .changeFloor {
        position: absolute;
        top: 6.875rem;
        right: -0.5rem;
        letter-spacing: 0.038rem;
        @include font-main(0.625rem, rgba(0, 0, 0, 0.6), $regular, 1.125rem);
        @include prefix(
          transform,
          rotate(-90deg),
          ("webkit", "moz", "ms", "o")
        );
      }
      .seeMoreCircles {
        position: absolute;
        right: 1.75rem;
        top: 2.188rem;
        height: 0.563rem;
        line-height: 0.5rem;
        li {
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.063rem;
          .circle {
            width: 0.156rem;
            height: 0.5rem;
            border-radius: 0;
          }
        }
      }
    }
    .positionRelative {
      height: calc(100% - 2.5rem);
      top: 0.938rem;
      position: relative;
      border: 0.063rem solid #dedede;
      border-right: none;
      background: #f7f7f7;
      padding: 1.25rem 1.875rem;
      border-radius: 0.625rem 0 0 0;
      .btnReg {
        width: 11.563rem;
      }
      .floorsButtons {
        margin-bottom: 1.875rem;
        li {
          margin-bottom: 0.438rem;
        }
      }
    }
  }
  .floorSpaces {
    position: absolute;
    top: -5.938rem;
    left: 7.5rem;
    padding: 0.938rem 0 0.313rem 0;
    width: calc(100% - 7.563rem);
    overflow-x: auto;
    transition: all 0.5s ease-in-out 0s;
    &.active {
      top: 0 !important;
    }
    .allFloors {
      display: none;
      white-space: nowrap;
    }
  }
  .buttonRequestOffer {
    padding: 0.938rem 3.75rem;
    width: 100%;
    background: $black;
    position: relative;
    .selectedSpaces {
      position: absolute;
      left: 3.75rem;
      bottom: 0.625rem;
      padding-bottom: 0.313rem;
      width: calc(100% - 20.313rem);
      overflow-x: auto;
    }
    .resultSelectedSpaces {
      white-space: nowrap;
      .row-floor {
        cursor: initial;
        background: linear-gradient(180deg, #353535, #232323);
        &:after {
          background: linear-gradient(180deg, #353535, #232323);
          border: 0.063rem solid rgba(222, 222, 222, 0.3);
        }
        &:before {
          background: linear-gradient(180deg, #353535, #232323);
        }
        .spaceFloor,
        span {
          color: $white;
        }
        .when-text {
          color: rgba(255, 255, 255, 0.4);
        }
        .removeItemDiv {
          opacity: 0;
          z-index: 9;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          transition: all 0.5s ease-in-out 0s;
          border-radius: 0 0.625rem 0.625rem 0.625rem;
          padding: 0.875rem 1.25rem;
          text-align: center;
          .removeItem {
            display: inline-block;
            width: 4.063rem;
            height: 2.875rem;
            outline: none;
            background-color: rgba(57, 57, 57, 0.8);
            border-radius: 0.625rem;
          }
        }
        &:hover {
          &:before {
            opacity: 0;
          }
          .removeItemDiv {
            opacity: 1;
            z-index: 99;
          }
        }
      }
    }
  }
  #contact {
    .scrollDisable {
      .colRightPopup {
        padding-bottom: 0;
      }
    }
  }
  #sendRequest {
    .contentCenter {
      text-align: center;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      height: 100%;
      .contentCenterWrapper {
        width: 100%;
      }
      h3 {
        margin-bottom: 1.25rem;
        color: $white;
      }
    }
  }
  // end style floor plans v2
  .popupBox {
    display: block;
    height: calc(100% - 2.5rem);
    overflow: auto;
    width: 25%;
    padding: 0.938rem 1.25rem;
    right: -30%;
    top: 1.25rem;
    margin-right: 1.25rem;
    border-radius: 0.625rem 0 0.625rem 0.625rem;
    background: white;
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
    z-index: 999;
    @include font-main(0.625rem, $black, $regular, 1rem);
    transition: all 0.3s ease-in-out 0s;
    img {
      margin-bottom: 1.25rem;
    }
    .title {
      margin-bottom: 0.938rem;
      text-transform: uppercase;
      @include font-main(1.375rem, $black, $light, 1.875rem);
    }
    p {
      margin-bottom: 0.625rem;
      @include font-main(0.875rem, $black, $regular, 1.5rem);
    }
    &.activate {
      right: 0;
    }
    .closeBox {
      position: absolute;
      top: 0;
      right: 0.313rem;
      content: "x";
      @include font-main(1.25rem, $black, $bold, 1.375rem);
      cursor: pointer;
    }
  }
  .arrowCustom {
    width: 0.625rem;
    height: 0.375rem;
    line-height: 0.375rem !important;
    cursor: pointer;
    text-align: center !important;
    display: block;
    position: absolute;
    i {
      display: inline-block;
      border-color: $white transparent transparent transparent;
      border-style: solid;
      border-width: 0.375rem 0.313rem 0 0.25rem;
      height: 0;
      width: 0.625rem;
    }
  }
  .row-padding-5px {
    margin: 0 -0.938rem 0.5rem;
    padding: 0 0.313rem;
    &:last-child {
      margin-bottom: 0;
    }
    .col-padding-10px {
      padding: 0 0.625rem;
    }
    .col-padding-0px {
      padding: 0;
    }
  }
  .info-left-space {
    position: absolute;
    left: -18.75rem;
    top: 1.25rem;
    transition: all 0.5s ease-in-out 0s;
    &.active {
      left: 1.25rem;
    }
  }
  .canvas-buttons-wrapper {
    position: absolute;
    bottom: -6.25rem;
    left: 50%;
    white-space: nowrap;
    transition: all 0.5s ease-in-out 0s;
    transform: translateX(-50%);
    z-index: 3;
    &.active {
      bottom: 0.625rem;
    }
    .background-gray {
      padding: 0;
    }
    .inline-element {
      display: inline-block;
      vertical-align: middle;
    }
    .measurementsWrapper {
      margin-right: 2.5rem;
      .toggleButtonStyle {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        width: 2.813rem;
        height: 1.938rem;
        border-radius: 0.938rem;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.12);
        transition: all 0.2s ease-in-out 0s;
        span {
          display: block;
          width: 1.375rem;
          height: 1.375rem;
          border-radius: 1.375rem;
          background: $white;
          position: absolute;
          left: 0.25rem;
          top: 0.25rem;
          transition: all 0.2s ease-in-out 0s;
        }
        &.active {
          background: $main-color;
          span {
            left: 1.188rem;
          }
        }
      }
      p {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.625rem;
        text-transform: uppercase;
        @include font-main(0.75rem, #1d2227, $semiBold, 0.938rem);
      }
    }
    .fitoutBtnsList {
      li {
        display: inline-block;
        margin-right: 0.625rem;
      }
    }
  }
  .hidden-desktop-elements {
    display: none !important;
  }
  .cart-spaces {
    position: absolute;
    bottom: -6.25rem;
    right: 0.75rem;
    max-width: 100%;
    width: 25rem;
    border-radius: 0.25rem;
    background: $white;
    z-index: 999;
    padding: 0.625rem 1rem;
    cursor: pointer;
    @include prefix(
      transition,
      all 0.5s ease-in-out 0s,
      ("webkit", "moz", "ms", "o")
    );
    &:hover {
      background: #eaeaea;
      @media only screen and (max-width: 1200px) {
        background: $white;
      }
      .arrow-text {
        .custom-arrow {
          border-color: transparent transparent #4cc66d transparent;
          @media only screen and (max-width: 1200px) {
            border-color: transparent transparent #272626 transparent;
          }
        }
      }
    }
    &.activeDropDown:hover {
      .arrow-text {
        .custom-arrow {
          border-color: $white transparent transparent transparent;
        }
      }
    }
    &.active {
      bottom: 0.75rem;
    }
    &.activeDropDown {
      background: $black;
      .floor-info {
        span {
          color: $white;
          b {
            color: $white;
          }
          .icon {
            color: $white !important;
          }
        }
        .space-details {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      .arrow-text {
        .text {
          .active-text {
            display: block;
            color: rgba(255, 255, 255, 0.3);
          }
          .disabled-text {
            display: none;
          }
        }
        .custom-arrow {
          border-color: $white transparent transparent transparent;
          border-width: 0.438rem 0.313rem 0 0.313rem;
        }
      }
    }
    .floor-info {
      span {
        @include prefix(
          transition,
          all 0.2s ease-in-out 0s,
          ("webkit", "moz", "ms", "o")
        );
        @include font-main(0.875rem, #272626, $regular, 1.063rem);
        b {
          @include prefix(
            transition,
            all 0.2s ease-in-out 0s,
            ("webkit", "moz", "ms", "o")
          );
          @include font-main(0.875rem, $black, $bold, 1.063rem);
        }
      }
      .when-text,
      .result-sqm {
        font-weight: $light;
      }
      .inlineBlock {
        display: inline-block;
        vertical-align: middle;
      }
      .space-details {
        color: #5f5f5f;
        font-weight: $regular;
      }
    }
    .arrow-text {
      .text {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.313rem;
        span {
          color: #4cc66d;
          font-weight: $regular;
          @include prefix(
            transition,
            all 0.2s ease-in-out 0s,
            ("webkit", "moz", "ms", "o")
          );
        }
        .active-text {
          display: block;
        }
      }
      .custom-arrow {
        border-color: transparent transparent #272626 transparent;
        border-style: solid;
        border-width: 0 0.313rem 0.438rem 0.313rem;
        height: 0;
        width: 0;
        display: inline-block;
        vertical-align: middle;
        @include prefix(
          transition,
          all 0.2s ease-in-out 0s,
          ("webkit", "moz", "ms", "o")
        );
      }
    }
    .resultAllSpaces {
      h6 {
        display: inline-block;
        letter-spacing: 0.038rem;
        text-transform: uppercase;
        @include font-main(0.75rem, $white, $bold, 1rem);
      }
      span {
        display: inline-block;
        text-align: right;
        letter-spacing: 0.038rem;
        text-transform: uppercase;
        @include font-main(0.75rem, $white, $semiBold, 1rem);
      }
      .when-text {
        text-align: left;
        color: rgba(255, 255, 255, 0.4);
      }
      .icon {
        display: inline-block;
        vertical-align: middle;
        margin-top: -0.188rem;
        margin-right: 0.375rem;
        path,
        circle {
          stroke: $white;
        }
      }
      .btn-popup {
        .icon {
          display: none !important;
        }
      }
      .buttonArrow {
        position: relative;
        padding-left: 1.563rem;
        cursor: pointer;
        text-transform: uppercase;
        @include font-main(0.875rem, $white, $bold, 1rem);
        .arrowCustom {
          left: 0;
          top: 50%;
          @include prefix(
            transform,
            translateY(-50%),
            ("webkit", "moz", "ms", "o")
          );
        }
        &.active {
          .arrowCustom {
            i {
              border-color: transparent transparent $white transparent;
              border-width: 0 0.313rem 0.375rem 0.25rem;
            }
          }
        }
        .your-list {
          margin-right: 0.5rem;
          @include font-main(
            0.875rem,
            rgba(255, 255, 255, 0.6),
            $semiBold,
            1rem
          );
        }
        .count-nr {
          margin-left: 0.5rem;
          @include font-main(0.875rem, $white, $bold, 1rem);
        }
      }
      .text-right {
        span {
          margin-right: 2.5rem;
          color: rgba(255, 255, 255, 0.6);
          &:first-child {
            margin-right: 1.563rem;
          }
          .icon {
            color: rgba(255, 255, 255, 0.9);
          }
          span {
            margin: 0;
            color: $white;
          }
        }
      }
    }
    .all-spaces-selected {
      display: none;
      &.active {
        display: block;
      }
      width: 100%;
      position: absolute;
      bottom: 100%;
      right: 0;
      background: #f2f2f2;
      border-radius: 0.25rem;
      margin-bottom: 0.375rem;
      padding: 0 0.938rem;
      .floor-info-popup {
        padding: 0.625rem 0.063rem;
        background: $white;
        border-radius: 0 0 0.25rem 0.25rem;
        margin: 0 -0.938rem;
        span {
          @include font-main(0.875rem, $black, $light, 1.063rem);
          @media only screen and (max-width: 1200px) {
            font-size: 0.813rem;
          }
          .icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.375rem;
            margin-top: -0.188rem;
          }
          &.space-details {
            color: #8e8e8e;
            letter-spacing: -0.021rem;
          }
          &.space-not-added {
            color: #8e8e8e;
            letter-spacing: -0.021rem;
          }
          &.space-added {
            color: #4cc66d;
            letter-spacing: -0.021rem;
          }
          b {
            font-weight: $bold;
            font-size: 0.875rem;
            @media only screen and (max-width: 1200px) {
              font-size: 0.813rem;
            }
          }
        }
      }
      .btn-green,
      .btn-red,
      .btn-green-opacity {
        width: 100%;
        margin-top: 0.625rem;
      }
      .spaces-selected-info {
        padding: 1rem 0.063rem;
        .list-info {
          @include font-main(0.875rem, $black, $regular, 1.063rem);
          @media only screen and (max-width: 1200px) {
            font-size: 0.813rem;
          }
          span {
            color: #a7a7a7;
          }
        }
        .close-drop-down {
          letter-spacing: -0.021rem;
          @include font-main(0.875rem, rgba(0, 0, 0, 0.4), $regular, 1.063rem);
          @media only screen and (max-width: 1200px) {
            font-size: 0.813rem;
          }
          b {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0.313rem;
            color: $black;
            font-size: 1.125rem;
            margin-top: -0.063rem;
          }
        }
      }
      .row-margin-bottom-12px {
        margin-bottom: 0.75rem;
      }
      .scroll-y {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 22.813rem);
      }
      .add-content {
        .background-space-white {
          background: $white;
          padding: 0.75rem 1rem;
          border-radius: 0.25rem;
          margin-top: 0.75rem;
          &:first-child {
            margin-top: 0;
          }
          span {
            @include font-main(0.875rem, #272626, $regular, 1.063rem);
            @media only screen and (max-width: 1200px) {
              font-size: 0.813rem;
            }
            b {
              @include font-main(0.875rem, #272626, $bold, 1.063rem);
              @media only screen and (max-width: 1200px) {
                font-size: 0.813rem;
              }
            }
            &.result-sqm {
              margin-right: 1rem;
            }
            &.result-sqm,
            &.result-max-people {
              font-weight: $light;
            }
          }
          .floor-number {
            .nr-cube {
              margin-right: 0;
              margin-left: 0.313rem;
            }
          }
        }
      }
    }
    .nr-cube {
      display: inline-block;
      vertical-align: middle;
      width: 1.375rem;
      height: 1.375rem;
      text-align: center;
      background: $black;
      border-radius: 0.25rem;
      margin-top: -0.125rem;
      margin-right: 0.313rem;
      @include font-main(
        0.875rem,
        $white !important,
        $bold !important,
        1.5rem !important
      );
      @media only screen and (max-width: 1200px) {
        font-size: 0.813rem;
      }
      &.nr-cube-white {
        color: $black !important;
        background: $white;
      }
    }
  }
  .tabs-matterport {
    position: relative;
    padding: 2rem 0 0;
    @media only screen and (max-width: 767px) {
      padding: 1rem 0 0;
    }
    .top-title {
      padding: 0 3.75rem;
      margin-bottom: 2.25rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 0.938rem;
        padding: 0 0.625rem;
      }
      .atom-title {
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.625rem;
        }
        h2 {
          text-transform: none;
          @include font-main(3.25rem, $black !important, $regular, 3.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(
              1.375rem,
              $black !important,
              $regular,
              1.625rem
            );
          }
          b {
            @include font-main(3.25rem, $black !important, $bold, 3.875rem);
            @media only screen and (max-width: 767px) {
              @include font-main(
                1.375rem,
                $black !important,
                $bold,
                1.625rem
              );
            }
          }
        }
        h3 {
          @include font-main(
            1.375rem,
            $black !important,
            $regular,
            1.625rem
          );
          @media only screen and (max-width: 767px) {
            @include font-main(
              0.875rem,
              $black !important,
              $regular,
              1.188rem
            );
          }
        }
      }
      .button-atom {
        background: transparent;
        padding: 0.75rem 2rem;
        font-weight: $regular;
        border-color: $blue;
        @media only screen and (max-width: 767px) {
          padding: 0.5rem 0.938rem;
          width: 100%;
        }
        span {
          color: $black;
          text-transform: none;
        }
        &:hover {
          background: $blue;
          span {
            color: $white;
          }
        }
      }
    }
    .tabs {
      padding: 0 3.75rem;
      @media only screen and (max-width: 767px) {
        padding: 0 0.625rem;
      }
      .tab-trigger {
        text-transform: uppercase;
        padding-bottom: 0.188rem;
        border-bottom: 0.25rem solid transparent;
        display: inline-block;
        cursor: pointer;
        margin-right: 2rem;
        transition: all 0.2s ease-in-out 0s;
        @include font-main(1.125rem, $black, $bold, 1.313rem);
        @media only screen and (max-width: 767px) {
          padding-bottom: 0.188rem;
          border-bottom: 0.25rem solid transparent;
          margin-right: 1.25rem;
          @include font-main(0.875rem, $black, $bold, 1.063rem);
        }
        &:hover {
          color: $blueDark;
        }
        &.active {
          color: $blueDark;
          border-color: $blueDark;
        }
      }
    }
    .content-position {
      position: relative;
      #matterport {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .floor-view {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white;
        @media only screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          display: block;
          margin: 0 auto;
          max-height: 100%;
        }
      }
      .tab-content {
        @media only screen and (max-width: 767px) {
          min-height: 30rem;
        }
      }
    }
    .atom-image-slider {
      .background-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        z-index: 9;
      }
    }
    .image-relative {
      @media only screen and (max-width: 767px) {
        min-height: 30rem;
      }
    }
    .owl-nav {
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &.owl-prev {
          left: 3.75rem;
        }
        &.owl-next {
          right: 3.75rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .space-combined {
      left: 50%;
      width: 22.5rem;
      @include prefix(
        transform,
        translateX(-50%),
        ("webkit", "moz", "ms", "o")
      );
      &.active {
        bottom: 0.313rem;
      }
      p {
        width: 50%;
        text-align: left;
        float: left;
        padding: 0.25rem 0.625rem 0.188rem 0;
        margin: 0;
      }
      a {
        width: 50%;
        float: left;
      }
    }
    .hidden-mobile-elements {
      display: none !important;
    }
    .hidden-desktop-elements {
      display: block !important;
    }
    .mobile-text-left {
      text-align: left !important;
    }
    .section-spaces.canvas-buttons-wrapper {
      margin-left: -5.5rem;
      z-index: 99;
      &.active {
        bottom: 2.813rem;
      }
      .select-style {
        select {
          width: 8.125rem;
        }
        label {
          padding: 0.313rem 12.7px;
        }
      }
      .select2-container {
        min-width: 8.125rem;
        margin: 0;
        .select2-selection--single {
          width: 8.125rem;
          .select2-selection__arrow {
            height: 2.188rem;
            width: 2.188rem;
          }
        }
        &:hover {
          .select2-selection--single {
            background: $white;
            .select2-selection__arrow {
              b {
                border-color: transparent transparent $black transparent;
              }
            }
          }
        }
      }
      .info-fit-out {
        .fit-out-text,
        .info-icon {
          z-index: 9999;
          font-size: 0.813rem;
        }
      }
    }
    .cart-spaces {
      width: 10.625rem;
      height: 4.125rem;
      right: 50%;
      bottom: -6.25rem;
      margin-right: -5.625rem;
      @include prefix(transform, translateX(50%), ("webkit", "moz", "ms", "o"));
      &.active {
        bottom: 2.813rem;
      }
      .all-spaces-selected {
        width: 21.938rem;
        .scroll-y {
          max-height: calc(100vh - 29.375rem);
        }
      }
      .floor-info {
        span {
          font-size: 0.813rem;
          b {
            font-size: 0.813rem;
          }
        }
        .space-details {
          color: #2bb751;
        }
      }
    }
  }
}
.select2-container {
  .fitout-dropdown-list {
    @media only screen and (max-width: 1200px) {
      width: 21.938rem !important;
      margin-left: -2.813rem;
      max-width: 21.938rem;
    }
    &.select2-dropdown--above {
      bottom: 0.5rem;
    }
    &.select2-dropdown--below {
      top: 0.5rem;
    }
    .select2-results {
      .select2-results__options {
        border: none;
        border-radius: 0.25rem;
        padding: 0.5rem;
        max-height: 20.625rem;
        background: $white;
        .select2-results__option {
          border-radius: 0.25rem;
          padding: 0.75rem !important;
          margin-bottom: 0.438rem;
          text-transform: initial;
          max-width: 19rem;
          @include font-main(0.875rem, $black, $regular, 1.375rem);
          @media only screen and (max-width: 1200px) {
            max-width: 357px;
            font-size: 0.813rem;
          }
          &[aria-disabled="true"] {
            background: #f7f7f7;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &.select2-results__option[aria-selected="true"],
          &.select2-results__option--highlighted[aria-selected] {
            color: $black;
          }
          &.select2-results__option[aria-selected="true"] {
            background: #e3e3e3;
          }
          &:hover {
            background: #f7f7f7;
            color: $black;
          }
        }
      }
    }
  }
}
</style>
