<template>
  <div class="organism-space">
    <div class="general-elements-style">
      <div class="slider-wrapper hidden-mobile-elements active" ref="sliderComponent">
        <span class="plus hshsh"  v-on:click="increaseZoomValue"><AtomIcon :size="16" icon="zoom-in" /></span>
        <div class="slider-parent">
          <div id="slider">
            <i class="line line1"></i>
            <div id="dragger" ref="dragger" class="ui-slider-handle" draggable="true" style="bottom: 0%;"
                 v-on:drag="handleDrag"
                 v-on:dragstart="handleDragStart">ZOOM</div>
          </div>
        </div>
        <span class="minus" v-on:click="decreaseZoomValue" ><AtomIcon :size="16" icon="zoom-out" /></span>
      </div>
      <div class="buttons-right active">
        <div class="buttons-3D">
          <div class="button-tooltip" ref="button2d">
            <span :class="{
              'btn-tooltip': true,
              'change-view': true,
              'text2D': true,
              'iconTooltip': true
            }" v-on:click="changeViewMode2D">2D</span>
            <p class="hidden-mobile-elements">Select 2D View Mode</p>
          </div>
          <div class="button-tooltip" ref="button3d">
            <span :class="{
              'btn-tooltip': true,
              'change-view': true,
              'text3D': true,
              'iconTooltip': true,
              'active': true
            }" v-on:click="changeViewMode3D">3D</span>
            <p class="hidden-mobile-elements">Select 3D View Mode</p>
          </div>
          <div class="button-tooltip" ref="buttonVt">
            <span :class="{
              'btn-tooltip': true,
              'change-view': true,
              'walkThrough': true,
              'iconTooltip': true
            }" v-on:click="changeViewModeFirstPerson"><AtomIcon icon="street-view" :size="20" /></span>
            <p class="hidden-mobile-elements">Enter Virtual Tour</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../../atoms/common/AtomIcon";
import {CurrentView, FloorViewMode} from "./store";
import {BabylonInitializeHelper} from "./store/helpers";

const isMobile = true;

export default {
  name: "Organism3DModelInteraction",
  components: {AtomIcon},
  data() {
    return {
      viewModes: FloorViewMode,
      zoomSettings:{
        zoomValue: 0,
        minValue: 0,
        maxValue: 45
      },
      isSpaceSelectionOpen: false,
      showCartSpacesMobile: !isMobile
    };
  },
  computed: {
    babylonHelper() {
      return BabylonInitializeHelper
    },
    buildingStore() {
      return this.$parent.$refs.buildingRef.buildingStore;
    },
    floorInteractions() {
        return this.babylonHelper.floorInteractionHelper();
    },
    virtualTour() {
      return this.babylonHelper.virtualTourHelper();
    },
    fitoutData() {
      return this.buildingStore.state.fitoutData.data;
    },
    isFloorView() {
      return this.buildingStore.state.currentView === CurrentView.FLOOR;
    },
    viewMode() {
      return this.buildingStore.state.floorViewMode;
    },
    currentSpace() {
      return this.buildingStore.state.space.spaceData || {};
    },
    spaceBuilding() {
      return this.$store.state.project.project.buildings.find(b => b.id === this.currentSpace.building_id);
    },
    spaceFloor() {
      return this.$store.state.project.project.floors.find(f => f.id === this.currentSpace.floor_id);
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length;
    },
  },
  watch: {
    viewMode(value, old) {
      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem;
        });
      };
      if (value !== old) {
        switch(value) {
          case this.viewModes.FIRST_PERSON:
            this.floorInteractions.highlightHelper.showPinMeshes(true, this.viewModes.FIRST_PERSON);
            this.floorInteractions.highlightHelper.showSidebarCategoryItems(this.viewModes.FIRST_PERSON)
            this.$refs.buttonVt.querySelector('span').classList.add('active');
            getSiblings(this.$refs.buttonVt).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active');
            });
            this.$refs.sliderComponent.classList.remove('active')
            break;
          case this.viewModes.TWO_DIMENSIONAL:
            this.floorInteractions.highlightHelper.showPinMeshes(true, this.viewModes.TWO_DIMENSIONAL);
            this.floorInteractions.highlightHelper.showSidebarCategoryItems(this.viewModes.TWO_DIMENSIONAL)
            this.$refs.button2d.querySelector('span').classList.add('active');
            getSiblings(this.$refs.button2d).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active');
              this.$refs.sliderComponent.classList.add('active')
            });
            break;
          case this.viewModes.DEFAULT:
            this.floorInteractions.highlightHelper.showPinMeshes(true, this.viewModes.DEFAULT);
            this.floorInteractions.highlightHelper.showSidebarCategoryItems(this.viewModes.DEFAULT)
            this.$refs.button3d.querySelector('span').classList.add('active');
            getSiblings(this.$refs.button3d).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active');
              this.$refs.sliderComponent.classList.add('active')
            });
            break;
        }
      }
    },
    isSpaceSelectionOpen(value) {
      if (value) {
        this.$refs.listNode.classList.add('active');
        this.$refs.hideText.style.display = 'block';
        this.$refs.requestOfferText.style.display = 'none';
      } else {
        this.$refs.listNode.classList.remove('active');
        this.$refs.requestOfferText.style.display = 'block';
        this.$refs.hideText.style.display = 'none';
      }
    }
  },
  methods: {
    changeFitout(value){
      this.floorInteractions.loadFitout(value);
      this.virtualTour && this.virtualTour.updateMinimap();
    },
    changeViewMode2D(){
      if (this.viewMode === FloorViewMode.TWO_DIMENSIONAL) return;
      (this.viewMode === FloorViewMode.FIRST_PERSON) && this.babylonHelper.virtualTourHelper().deactivate();
      this.buildingStore.dispatch('changeViewMode', FloorViewMode.TWO_DIMENSIONAL);
      this.babylonHelper.floorInteractionHelper().changeViewMode(FloorViewMode.TWO_DIMENSIONAL);
    },
    changeViewMode3D(){
      if (this.viewMode === FloorViewMode.DEFAULT) return;
      (this.viewMode === FloorViewMode.FIRST_PERSON) && this.babylonHelper.virtualTourHelper().deactivate();
      this.buildingStore.dispatch('changeViewMode', FloorViewMode.DEFAULT);
      this.babylonHelper.floorInteractionHelper().changeViewMode(FloorViewMode.DEFAULT);
    },
    changeViewModeFirstPerson(){
      if (this.viewMode === FloorViewMode.FIRST_PERSON) return;
      this.buildingStore.dispatch('changeViewMode', FloorViewMode.FIRST_PERSON);
      this.babylonHelper.virtualTourHelper().activate();
    },
    handleDrag(e){
      if (e.target.id !=='dragger'){
        return;
      }
      const parentRect = e.target.parentElement.getBoundingClientRect();
      const zoomRect = e.target.getBoundingClientRect();
      const cursorVal = e.pageY!== 0 ? (parentRect.bottom - e.pageY)-zoomRect.height/2 : 0;
      if (cursorVal > 0 &&
          cursorVal <= this.zoomSettings.maxValue-zoomRect.height/2 &&
          cursorVal >= this.zoomSettings.minValue
      )
      {
        this.zoomSettings.zoomValue = cursorVal;
        this.floorInteractions.setZoomValue(cursorVal);
        const percentVal = cursorVal * 100 / this.zoomSettings.maxValue;
        this.$refs.dragger.style.bottom =  percentVal>5 ? `${percentVal}%` : `${0}%`;
      }
    },
    handleDragStart(e) {
      if (e.target.id !=='dragger'){
        return;
      }
        e.dataTransfer.setDragImage(e.target, window.outerWidth+100, window.outerHeight+100);
    },
    increaseZoomValue() {
      if (this.zoomSettings.zoomValue < this.zoomSettings.maxValue-5){
        this.zoomSettings.zoomValue < this.zoomSettings.maxValue-10 ? this.zoomSettings.zoomValue +=5 : this.zoomSettings.zoomValue = this.zoomSettings.maxValue-5;
        this.floorInteractions.setZoomValue(this.zoomSettings.zoomValue)
        this.$refs.dragger.style.bottom = `${this.zoomSettings.zoomValue*100/this.zoomSettings.maxValue}%`;
      }
    },
    decreaseZoomValue() {
      if (this.zoomSettings.zoomValue > this.zoomSettings.minValue){
        this.zoomSettings.zoomValue > 5 ? this.zoomSettings.zoomValue -=5 : this.zoomSettings.zoomValue = 0;
        this.floorInteractions.setZoomValue(this.zoomSettings.zoomValue)
        this.$refs.dragger.style.bottom = `${this.zoomSettings.zoomValue*100/this.zoomSettings.maxValue}%`;
      }
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return;
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(modal.withNamespace(modal.action.CHANGE_MODAL_STATE), { showModal: true, modalType: "request-offer" });
      document.body.classList.add('disable-scroll');
    },
    toggleSpaceSelectionDetails() {
      this.isSpaceSelectionOpen = !this.isSpaceSelectionOpen;
      this.showCartSpacesMobile = !this.showCartSpacesMobile;
    },
    closeSpaceSelectionDetails() {
      this.isSpaceSelectionOpen = false;
      this.showCartSpacesMobile = false;
    },
    addCurrentSpaceToCart() {
      const space = {
        ...this.currentSpace,
        building: this.spaceBuilding,
        floor: this.spaceFloor
      };
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(requestOffer.withNamespace(requestOffer.action.ADD_SPACE), space);
    },
    removeSpaceFromCart(space) {
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE), space);
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find(space => space.id === spaceId);
    }
  },
  mounted() {
    //this.floorInteractions.highlightHelper.showSidebarCategoryItems(this.viewModes.TWO_DIMENSIONAL)

  }
}
</script>

<style lang="scss">

  .organism-space {
    .space-combined {
      position: absolute;
      left: 80px;
      bottom: -300px;
      z-index: 9;
      padding: 10px 16px;
      background: $white;
      width: 250px;
      border-radius: 4px;
      text-align: center;
      transition: all 0.5s ease-in-out 0s;
      &.active {
        bottom: 0.625rem;
      }
      p {
        font-size: 14px;
        line-height: 17px;
        color: #272626;
        font-weight: $regular;
        margin-bottom: 10px;
      }
      a {
        width: 100%;
      }
    }
    .section-spaces.canvas-buttons-wrapper{
      .background-gray {
        padding: 0;
      }
      .select-style {
        select {
          width: 320px;
          font-size: 12px;
        }
        label {
          height: 66px;
          background: #E9ECF0;
          padding: 5px 10px;
          font-size: 14px;
          border: none;
          color: $black;
          line-height: 66px;
          display: inline-block;
          vertical-align: top;
          text-transform: uppercase;
          max-width: 100%;
          border-radius: 4px 0 0 4px;
          cursor: pointer;
          margin-top: 0;
          margin-right: -2px;
        }
        .select-atom-wrapper {
          label {
            display: none;
          }
        }
      }
      .select-inline-block {
        position: relative;
        display: inline-block;
        vertical-align: top;
      }
      .info-fit-out {
        .fit-out-text {
          position: absolute;
          left: 12px;
          top: 12px;
          font-size: 14px;
          color: #5F5F5F;
          font-weight: 400;
          transition: all 0.2s ease-in-out 0s;
        }
        .select-text {
          position: absolute;
          right: 30px;
          top: 12px;
          font-size: 14px;
          color: #AAAAAA;
          font-weight: 400;
          transition: all 0.2s ease-in-out 0s;
        }
        .info-icon {
          position: absolute;
          bottom: 10px;
          right: 12px;
          cursor: pointer;
          transition: all 0.2s ease-in-out 0s;
          &:hover, &.active {
            color: $main-color;
          }
        }
      }
      .select2-container {
        min-width: 320px;
        .select2-selection--single {
          width: 320px;
          font-size: 12px;
          height: 66px;
          padding: 12px 45px 12px 12px;
          border-radius: 0 4px 4px 0;
          border: none;
          background: #fff;
          .select2-selection__rendered {
            font-size: 14px;
            line-height: 28px;
            text-transform: initial;
            padding-top: 20px;
            font-weight: 500;
            transition: all 0.2s ease-in-out 0s;
            @media only screen and (max-width: 1200px) {
              font-size: 13px;
            }
          }
          .select2-selection__arrow {
            height: 37px;
            margin-right: 5px;
            b {
              border-width: 0 5px 7px 5px;
              border-color: transparent transparent rgba(9, 9, 9, 0.4) transparent;
            }
          }
        }
        &:hover {
          .select2-selection--single {
            background: #EAEAEA;
            .select2-selection__arrow {
              b {
                border-color: transparent transparent #4CC66D transparent;
              }
            }
          }
        }
        &.select2-container--open, &.select2-container--open:hover {
          .select2-selection--single {
            background: $black;
            .select2-selection__rendered {
              color: $white;
            }
            .select2-selection__arrow {
              b {
                border-color: $white transparent transparent transparent;
                border-width: 7px 5px 0 5px;
              }
            }
          }
          & + .info-fit-out {
            .fit-out-text {
              color: rgba(255,255,255,0.6);
            }
            .select-text {
              color: rgba(255,255,255,0.4);
            }
            .info-icon {
              color: $white;
            }
          }
        }
      }
    }
    .general-elements-style {
      .slider-wrapper {
        position: absolute;
        left: -70px;
        top: 50%;
        z-index: 9;
        transition: all 0.5s ease-in-out 0s;
        transform: translateY(-50%);
        &.active {
          left: 0.625rem !important;
        }
        & > span {
          font-size: 16px;
          position: absolute;
          left: 50%;
          z-index: 9;
          cursor: pointer;
          transform: translateX(-50%);
          transition: all 0.2s ease-in-out 0s;
          .icon {
            color: #B4C5D6 !important;
          }
          &.plus {
            top: 8px;
          }
          &.minus {
            bottom: 8px;
          }
          &:hover {
            .icon {
              color: $black !important;
            }
          }
        }
        .slider-parent {
          position: relative;
          width: 33px;
          background: $white;
          padding: 38px 0;
          border-radius: 6px;
          #slider {
            position: relative;
            width: 100%;
            height: auto;
            padding: 0;
            i {
              display: block;
              width: 2px;
              margin: 0 auto;
              height: 66px;
              background: #D8D8D8;
              transition: all 0.2s ease-in-out 0s;
            }
            .ui-slider-handle {
              display: block;
              position: absolute;
              left: 50%;
              z-index: 9;
              overflow: hidden;
              background: $black;
              width: 11px;
              height: 11px;
              border-radius: 11px;
              text-align: center;
              cursor: pointer;
              font-size: 8px;
              color: $black;
              line-height: 11px;
              font-weight: $bold;
              transform: translateX(-50%);
              transition: width 0.2s ease-in-out 0s, border-radius 0.2s ease-in-out 0s, color 0.5s ease-in-out 0s;
            }
          }
        }
        &:hover {
          .slider-parent {
            #slider {
              i {
                width: 4px;
              }
              .ui-slider-handle {
                width: 31px;
                border-radius: 2px;
                color: $white;
              }
            }
          }
        }
      }
      .buttons-right {
        position: absolute;
        right: -85px;
        top: 50%;
        background: $white;
        border-radius: 6px;
        padding: 6px 12px;
        z-index: 9;
        transition: all 0.5s ease-in-out 0s;
        transform: translateY(-50%);
        &.active {
          right: 0.625rem;
        }
      }

      .saveFitoutSection{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -100px;
        transition: all 0.5s ease-in-out 0s;
        &.active {
          top: 0;
        }
        .newButtonsFitout{
          margin-top: 20px;
          display: inline-block;
          vertical-align: top;
          .btnText {
            display: inline-block;
            vertical-align: top;
            .btnShareFitout, .btnShareFitoutText, .btnDropdownOlderFitouts, .btnDropwdownText, .btnRevertFitoutVersion, .btnRevertFitoutVersionText{
              margin-left: 15px;
            }
            p {
              font-size: 8px;
              color: $black;
              line-height: 12px;
              font-weight: $bold;
              margin-top: 5px;
              text-align: center;
              max-width: 40px;
              transition: all 0.2s ease-in-out 0s;
            }
            .btnDropdownOlderFitouts{
              position: relative;
              display: inline-block;
            }
            .dropdownContent {
              display: none;
              position: absolute;
              background: $white;
              min-width: 250px;
              margin-top: 5px;
              box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
              border-radius: 8px;
              z-index: 1;
              overflow-y: scroll;
              max-height: 250px;
              a, span {
                font-size: 12px;
                color: rgba(0,0,0,0.6);
                line-height: 16px;
                font-weight: $regular;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
              }
              a:hover, span:hover {
                background: #eeeeee;
              }
              span {
                text-align: center;
              }
            }
            .dropdownContent::-webkit-scrollbar-track {
              padding: 2px 0;
            }
            .dropdownContent::-webkit-scrollbar {
              width: 4px;
            }
            .dropdownContent::-webkit-scrollbar-thumb {
              box-shadow: inset 0 0 6px rgba(0,0,0,.3);
              background-color: $main-color;
            }
            .btnRevertFitoutVersion {
              .svg-inline--fa {
                color: $red;
              }
            }
            .btnRevertFitoutVersion:hover {
              background: $red;
              .svg-inline--fa {
                color: rgba(255,255,255,0.9);
              }
            }
          }
        }
        .inputStyle {
          margin: 20px;
          .label {
            font-size: 14px;
            color: rgba(0,0,0,0.6);
            line-height: 19px;
            font-weight: $regular;
            margin-bottom: 7px;
          }
          .inputFocus {
            input {
              width: 100%;
              padding: 0 16px;
              color: $black;
              background: $white;
              border: 2px solid $main-color;
            }
          }
        }
      }

      .modalOverlay {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 999998;
        background-color: rgba(0,0,0,0.3);
      }
      .popupConfirm{
        display: none;
        position: fixed;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 50%;
        z-index: 999999;
        background: rgba(0, 0, 0, 0.9);
        overflow: hidden;
        padding: 35px 15px;
        .btnClosePopup {
          position: absolute;
          top: 5%;
          right: 2%;
          color: $white;
          cursor: pointer;
          font-weight: 600;
        }
        .titleSubtitle {
          font-size: 16px;
          line-height: 19px;
          color: $white;
          font-weight: $regular;
          width: 100%;
          height: 70%;
          overflow: hidden;
        }
        h2 {
          font-size: 20px;
          color: $white;
          line-height: 19px;
          font-weight: $bold;
          margin-bottom: 20px;
        }
        .contentWrapper{
          margin-bottom: 20px;
        }
        .rightButtons {
          bottom: 5%;
          text-align: end;
        }
      }

      .popupScreen {
        .titleEmail{
          margin-top: 30px;
          margin-bottom: 20px;
          .title {
            color: $white;
            display: inline-block;
            vertical-align: middle;
            margin: 0 20px 0 0;
          }
          .btn {
            display: inline-block;
            vertical-align: middle;
          }
        }
        .removeInputsEmail {
          position: absolute;
          right: -8px;
          font-weight: 700;
          color: $white;
          top: -8px;
          cursor: pointer;
          display: block;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 15px;
          border: 2px solid #f20303;
          background: #f20303;
          border-radius: 20px;
          font-size: 14px;
        }
        .linkDiv{
          span{
            font-size: 14px;
            line-height: 19px;
            color: $white;
            font-weight: $regular;
          }

          .clipboard{
            color: white;
            right: 1%;
            top: 16%;
            position: absolute;
          }
        }
      }
      .canvas-buttons-wrapper {
        .infoScreen {
          display: none;
        }
      }
      .custom-tooltip {
        position: relative;
        margin-left: 5px;
        text-align: left;
        display: none;
        .btnBorder {
          margin: 0;
        }
      }
      .legends-space {
        position: absolute;
        right: 0;
        bottom: 100%;
        width: 356px;
        border-radius: 4px;
        margin-bottom: 8px;
        z-index: -1;
        &.activeHover {
          right: 100%;
          margin-right: 8px;
          .closeCustomTooltip {
            display: none !important;
          }
          h6 {
            text-align: center !important;
          }
        }
        .fitoutLegend {
          display: none;
          background: $white;
          padding: 20px;
          h6 {
            font-size: 14px;
            line-height: 17px;
            color: #9A9A9A;
            font-weight: $regular;
            letter-spacing: -0.34px;
            margin-bottom: 12px;
          }
          h5 {
            font-size: 14px;
            line-height: 17px;
            color: $black;
            font-weight: $medium;
            text-align: center;
            padding: 8px 10px;
            background: #F7F7F7;
            border-radius: 4px;
            margin-bottom: 16px;
          }
          .closeCustomTooltip {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 16px;
            font-size: 20px;
            transition: all 0.2s ease-in-out 0s;
            &:hover {
              color: $main-color;
            }
          }
          li {
            clear: both;
            display: flex;
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            .width60 {
              font-size: 14px;
              line-height: 17px;
              color: #A7A7A7;
              font-weight: $regular;
              text-transform: capitalize;
              float: left;
              width: 60%;
              padding-right: 5px;
            }
            .width40 {
              font-size: 14px;
              line-height: 17px;
              color: $black;
              font-weight: $medium;
              float: left;
              width: 40%;
              padding-left: 5px;
            }
            b {
              color: $black;
              font-weight: $medium;
            }
          }
          .arrowCustom {
            width: 20px;
            height: 11px;
            right: 9px;
            top: 101%;
            i {
              border-color: $white transparent transparent transparent;
              border-width: 11px 10px 0 10px;
              width: 20px;
            }
          }
        }
      }
      .annotationsSidebar {
        position: absolute;
        left: -250px;
        top: 100px;
        z-index: 8;
        padding: 15px 0;
        background: $white;
        width: 250px;
        border-radius: 3px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
        max-height: 31vh;
        overflow: auto;
        transition: all 0.5s ease-in-out 0s;
        &.active {
          left: 60px;
        }
        .sidebarTitle{
          display: none;
        }
        ul{
          margin-bottom: 10px;
        }
        .arrowCustom {
          display: block;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          i {
            border-color: rgba(0,0,0,0.4) transparent transparent transparent;
            transition: all 0.2s ease-in-out 0s;
          }
        }
        h5 {
          position: relative;
          font-size: 12px;
          line-height: 14px;
          color: $black;
          font-weight: $bold;
          transition: all 0.2s ease-in-out 0s;
          margin-bottom: 8px;
          padding: 0 15px;
        }
        li {
          font-size: 11px;
          line-height: 15px;
          color: rgba(0,0,0,0.6);
          font-weight: $regular;
          transition: all 0.2s ease-in-out 0s;
          cursor: pointer;
          position: relative;
          padding: 5px 15px;
          &:hover, &.active {
            background: $main-color;
            color: $white;
          }
          span {
            display: inline-block;
            vertical-align: middle;
          }
          .width80 {
            width: 70%;
          }
          .width20 {
            width: 30%;
          }
          b {
            display: inline-block;
            vertical-align: middle;
            @include font-main(10px, #282D32, $semiBold, 14px);
            font-size: 10px;
            line-height: 14px;
            color: #282D32;
            font-weight: $semiBold;
          }
        }
        &.activeSlide {
          h5 {
            color: $main-color;
            margin-bottom: 10px;
          }
          .arrowCustom {
            i {
              border-color: transparent transparent $main-color transparent !important;
              border-width: 0 5px 6px 4px;
            }
          }
        }
      }
      .vtPoints {
        position: absolute;
        bottom: 55px;
        left: -100px;
        transition: all 0.5s ease-in-out 0s;
        @media only screen and (max-width: 1200px) {
          bottom: initial;
          top: 55px;
          right: initial;
          left: -100px;
        }
        &.active {
          left: 60px;
          @media only screen and (max-width: 1200px) {
            left: 15px;
          }
        }
        .inline-element {
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
          @media only screen and (max-width: 1200px) {
            display: block;
            margin: 0 0 8px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .custom-tooltip {
          margin-left: 0;
          display: block;
          span {
            font-size: 26px;
            font-weight: 800;
            @media only screen and (max-width: 1200px) {
              font-size: 20px;
            }
          }
        }
        .hideElement {
          display: none;
          @media only screen and (max-width: 1200px) {
            width: 250px !important;
            bottom: initial;
            left: 100%;
            margin: 0 0 0 18px;
            top: 50%;
            transform: translateY(-50%);

            .arrowCustom {
              top: 50%;
              left: -17px;
              @include prefix(transform, translateY(-50%) rotate(90deg), ('webkit', 'moz', 'ms', 'o'));
            }
          }
        }
      }
    }
    .canvasBox {
      position: relative;
      background-color: #F7F7F7;
      height: 100vh;
      width: 100%;
      overflow: hidden;
    }
    .mapFirstPerson {
      display: none !important;
      width: 300px;
      position: absolute;
      top: 82px;
      right: 20px;
      border-radius: 10px;
      opacity: 0.5;
      overflow: hidden;
      transition: all 0.5s ease-in-out 0s;
      &:hover {
        opacity: 1;
      }
      svg {
        display: block;
      }
      .circlePin {
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background: $main-color;
        border: 1px solid $main-color;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -5px;
        margin-left: -5px;
        z-index: 4;
        //include prefix(transition, all 0.4s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
        transition: top .7s, left .7s;
        &:after {
          content: '';
          display: block;
          background: $main-color;
          opacity: 0;
          width: 3px;
          height: 3px;
          border-radius: 3px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &:hover {
          background: rgba(255,255,255,0.8);
        }
        &.active {
          background: rgba(255,255,255,0.8);
          &:after {
            opacity: 1;
          }
          &:before {
            content: "";
            display: inline-block;
            width: 51px;
            height: 50px;
            position: absolute;
            background-image: url('../../../../assets/images/cone.svg');
            transform: rotate(55deg);
            top: 12px;
            left: -25px;
            z-index: 1;
            pointer-events:none;
          }
        }
      }


      .pointerCircle{
        transition: none;
        z-index: 4;
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background: $main-color;
        border: 1px solid $main-color;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -5px;
        margin-left: -5px;
        pointer-events:none;
      }
    }
    .overlay{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: .2;
      z-index: 10;
    }
    #leftFloors {
      position: absolute;
      left: -230px;
      top: 0;
      height: 100%;
      z-index: 99;
      transition: all 0.5s ease-in-out 0s;
      .asideInfoTitle{
        @include font-main(14px, $white, $regular, 24px);
        color: #707070;
        display: block;
        margin-bottom: 15px;
      }
      .sidebarRight {
        position: absolute;
        right: -110px;
        top: 8px;
        z-index: 9;
        .floorVisible {
          display: block;
          position: absolute;
          top: 24px;
          left: 38px;
          text-align: center;
          @include font-main(10px, $black, $bold, 18px);
          #floor-number {
            letter-spacing: -0.48px;
            display: block;
            @include font-main(12px, $black, $bold, 21px);
          }
        }
        .changeFloor {
          position: absolute;
          bottom: 111px;
          left: -5px;
          letter-spacing: 0.6px;
          @include font-main(10px, rgba(0,0,0,0.6), $regular, 18px);
          @include prefix(transform, rotate(-90deg), ('webkit', 'moz', 'ms', 'o'));
        }
        .seeMoreCircles {
          position: absolute;
          left: 28px;
          bottom: 35px;
          height: 9px;
          line-height: 8px;
          li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 1px;
            .circle {
              width: 2.5px;
              height: 8px;
              border-radius: 0;
            }
          }
        }
      }
      .positionRelative {
        background: $white;
        height: calc(100% - 40px);
        top: 15px;
        position: relative;
        border: 1px solid #dedede;
        border-left: none;
        background: #f7f7f7;
        padding: 20px 30px;
        border-radius: 0 0 10px 0;


        .floorsButtons {
          ul {
            max-width: 205px;
            overflow: hidden;
            overflow-y: auto;
            height: 675px;
            padding: 5px;
            margin-left: -5px;
          }
          li {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 3px;
          }
          .btnReg {
            width: 60px;
          }
        }
      }
    }
    #rightFloors {
      position: absolute;
      bottom: 0;
      right: -230px;
      height: 100%;
      z-index: 99;
      transition: all 0.5s ease-in-out 0s;
      .asideInfoTitle{
        @include font-main(14px, $white, $regular, 24px);
        color: #707070;
        display: block;
        margin-bottom: 15px;
      }
      .sidebarLeft {
        position: absolute;
        left: -109px;
        bottom: 10px;
        z-index: 9;
        .changeFloor {
          position: absolute;
          top: 110px;
          right: -8px;
          letter-spacing: 0.6px;
          @include font-main(10px, rgba(0,0,0,0.6), $regular, 18px);
          @include prefix(transform, rotate(-90deg), ('webkit', 'moz', 'ms', 'o'));
        }
        .seeMoreCircles {
          position: absolute;
          right: 28px;
          top: 35px;
          height: 9px;
          line-height: 8px;
          li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 1px;
            .circle {
              width: 2.5px;
              height: 8px;
              border-radius: 0;
            }
          }
        }
      }
      .positionRelative {
        height: calc(100% - 40px);
        top: 15px;
        position: relative;
        border: 1px solid #dedede;
        border-right: none;
        background: #f7f7f7;
        padding: 20px 30px;
        border-radius: 10px 0 0 0;

        .btnReg {
          width: 185px;
        }
        .floorsButtons {
          margin-bottom: 30px;
          li {
            margin-bottom: 7px;
          }
        }
      }
    }
    .floorSpaces {
      position: absolute;
      top: -95px;
      left: 120px;
      padding: 15px 0 5px 0;
      width: calc(100% - 121px);
      overflow-x: auto;
      transition: all 0.5s ease-in-out 0s;
      &.active {
        top: 0 !important;
      }
      .allFloors {
        display: none;
        white-space: nowrap;
      }
    }
    .buttonRequestOffer {
      padding: 15px 60px;
      width: 100%;
      background: $black;
      position: relative;
      .selectedSpaces {
        position: absolute;
        left: 60px;
        bottom: 10px;
        padding-bottom: 5px;
        width: calc(100% - 325px);
        overflow-x: auto;
      }
      .resultSelectedSpaces {
        white-space: nowrap;
        .row-floor {
          cursor: initial;
          background: linear-gradient(180deg, #353535, #232323);
          &:after {
            background: linear-gradient(180deg, #353535, #232323);
            border: 1px solid rgba(222,222,222,0.3);
          }
          &:before {
            background: linear-gradient(180deg, #353535, #232323);
          }
          .spaceFloor, span {
            color: $white;
          }
          .when-text {
            color: rgba(255,255,255,0.4);
          }
          .removeItemDiv {
            opacity: 0;
            z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.8);
            transition: all 0.5s ease-in-out 0s;
            border-radius: 0 10px 10px 10px;
            padding: 14px 20px;
            text-align: center;
            .removeItem {
              display: inline-block;
              width: 65px;
              height: 46px;
              outline: none;
              background-color: rgba(57, 57, 57, 0.8);
              border-radius: 10px;
            }
          }
          &:hover {
            &:before {
              opacity: 0;
            }
            .removeItemDiv {
              opacity: 1;
              z-index: 99;
            }
          }
        }
      }
    }
    #contact {
      .scrollDisable {
        .colRightPopup {
          padding-bottom: 0;
        }
      }
    }
    #sendRequest {
      .contentCenter {
        text-align: center;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        height: 100%;

        .contentCenterWrapper {
          width: 100%;
        }
        h3 {
          margin-bottom: 20px;
          color: $white;
        }
      }
    }
    // end style floor plans v2
    .popupBox{
      display: block;
      height: calc(100% - 40px);
      overflow: auto;
      width: 25%;
      padding: 15px 20px;
      right: -30%;
      top: 20px;
      margin-right: 20px;
      border-radius: 10px 0 10px 10px;
      background: white;
      box-shadow: 0 2px 6px rgba(0,0,0,0.05);
      z-index: 999;
      @include font-main(10px, $black, $regular, 16px);
      transition: all 0.3s ease-in-out 0s;
      img {
        margin-bottom: 20px;
      }
      .title{
        @include font-main(22px, $black, $light, 30px);
        margin-bottom: 15px;
        text-transform: uppercase;
      }
      p {
        @include font-main(14px, $black, $regular, 24px);
        margin-bottom: 10px;
      }

      &.activate{
        right: 0;
      }

      .closeBox{
        position: absolute;
        top: 0;
        right: 5px;
        content: "x";
        @include font-main(20px, $black, $bold, 22px);
        cursor: pointer;
      }

    }
    .arrowCustom {
      width: 10px;
      height: 6px;
      line-height: 6px !important;
      cursor: pointer;
      text-align: center !important;
      display: block;
      position: absolute;
      i {
        display: inline-block;
        border-color: $white transparent transparent transparent;
        border-style: solid;
        border-width: 6px 5px 0 4px;
        height: 0;
        width: 10px;
      }
    }
    .row-padding-5px {
      margin: 0 -15px 8px;
      padding: 0 5px;
      &:last-child {
        margin-bottom: 0;
      }
      .col-padding-10px {
        padding: 0 10px;
      }
      .col-padding-0px {
        padding: 0;
      }
    }
    .info-left-space {
      position: absolute;
      left: -300px;
      top: 20px;
      transition: all 0.5s ease-in-out 0s;
      &.active {
        left: 20px;
      }
    }
    .canvas-buttons-wrapper{
      position: absolute;
      bottom: -100px;
      left: 50%;
      white-space: nowrap;
      transition: all 0.5s ease-in-out 0s;
      transform: translateX(-50%);
      z-index: 3;

      &.active {
        bottom: 0.625rem;
      }
      .background-gray {
        padding: 0;
      }
      .inline-element {
        display: inline-block;
        vertical-align: middle;
      }
      .measurementsWrapper {
        margin-right: 40px;
        .toggleButtonStyle {
          display: inline-block;
          position: relative;
          vertical-align: middle;
          width: 45px;
          height: 31px;
          border-radius: 15px;
          cursor: pointer;
          background: rgba(0,0,0,0.12);
          transition: all 0.2s ease-in-out 0s;
          span {
            display: block;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            background: $white;
            position: absolute;
            left: 4px;
            top: 4px;
            transition: all 0.2s ease-in-out 0s;
          }
          &.active {
            background: $main-color;
            span {
              left: 19px;
            }
          }
        }
        p {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          @include font-main(12px, #1D2227, $semiBold, 15px);
          text-transform: uppercase;
        }
      }
      .fitoutBtnsList{
        li{
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .hidden-desktop-elements {
      display: none !important;
    }
    .cart-spaces {
      position: absolute;
      bottom: -100px;
      right: 12px;
      max-width: 100%;
      width: 400px;
      border-radius: 4px;
      background: $white;
      z-index: 999;
      padding: 10px 16px;
      cursor: pointer;
      @include prefix(transition, all 0.5s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
      &:hover {
        background: #EAEAEA;
        @media only screen and (max-width: 1200px) {
          background: $white;
        }
        .arrow-text {
          .custom-arrow {
            border-color: transparent transparent #4CC66D transparent;
            @media only screen and (max-width: 1200px) {
              border-color: transparent transparent #272626 transparent;
            }
          }
        }
      }
      &.activeDropDown:hover {
        .arrow-text {
          .custom-arrow {
            border-color: $white transparent transparent transparent;
          }
        }
      }
      &.active {
        bottom: 12px;
      }
      &.activeDropDown {
        background: $black;
        .floor-info {
          span {
            color: $white;
            b {
              color: $white;
            }
            .icon {
              color: $white !important;
            }
          }
          .space-details {
            color: rgba(255,255,255,0.6);
          }
        }
        .arrow-text {
          .text {
            .active-text {
              display: block;
              color: rgba(255, 255, 255, 0.3)
            }
            .disabled-text {
              display: none;
            }
          }
          .custom-arrow {
            border-color: $white transparent transparent transparent;
            border-width: 7px 5px 0 5px;
          }
        }
      }
      .floor-info {
        span {
          font-size: 14px;
          line-height: 17px;
          color: #272626;
          font-weight: $regular;
          @include prefix(transition, all 0.2s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
          b {
            font-size: 14px;
            line-height: 17px;
            font-weight: $bold;
            color: $black;
            @include prefix(transition, all 0.2s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
          }
        }
        .when-text, .result-sqm {
          font-weight: $light;
        }
        .inlineBlock {
          display: inline-block;
          vertical-align: middle;
        }
        .space-details {
          color: #5F5F5F;
          font-weight: $regular;
        }
      }
      .arrow-text {
        .text {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          span {
            color: #4CC66D;
            font-weight: $regular;
            @include prefix(transition, all 0.2s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
          }
          .active-text {
            display: block;
          }
        }
        .custom-arrow {
          border-color: transparent transparent #272626 transparent;
          border-style: solid;
          border-width: 0 5px 7px 5px;
          height: 0;
          width: 0;
          display: inline-block;
          vertical-align: middle;
          @include prefix(transition, all 0.2s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
        }
      }
      .resultAllSpaces {
        h6 {
          display: inline-block;
          font-size: 12px;
          line-height: 16px;
          color: $white;
          font-weight: $bold;
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
        span {
          display: inline-block;
          text-align: right;
          font-size: 12px;
          line-height: 16px;
          color: $white;
          font-weight: $semiBold;
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
        .when-text {
          text-align: left;
          color: rgba(255,255,255,0.4);
        }
        .icon {
          display: inline-block;
          vertical-align: middle;
          margin-top: -3px;
          margin-right: 6px;
          path, circle {
            stroke: $white;
          }
        }
        .btn-popup {
          .icon {
            display: none !important;
          }
        }
        .buttonArrow {
          position: relative;
          padding-left: 25px;
          cursor: pointer;
          font-size: 14px;
          line-height: 16px;
          color: $white;
          font-weight: $bold;
          text-transform: uppercase;
          .arrowCustom {
            left: 0;
            top: 50%;
            @include prefix(transform, translateY(-50%), ('webkit', 'moz', 'ms', 'o'));
          }
          &.active {
            .arrowCustom {
              i {
                border-color: transparent transparent $white transparent;
                border-width: 0 5px 6px 4px;
              }
            }
          }
          .your-list {
            font-size: 14px;
            line-height: 16px;
            color:  rgba(255,255,255,0.6);
            font-weight: $semiBold;
            margin-right: 8px;
          }
          .count-nr {
            font-size: 14px;
            line-height: 16px;
            color: $white;
            font-weight: $bold;
            margin-left: 8px;
          }
        }
        .text-right {
          span {
            margin-right: 40px;
            color: rgba(255,255,255,0.6);
            &:first-child {
              margin-right: 25px;
            }
            .icon {
              color: rgba(255,255,255,0.9);
            }
            span {
              margin: 0;
              color: $white;
            }
          }
        }
      }
      .all-spaces-selected {
        display: none;

        &.active {
          display: block;
        }

        width: 100%;
        position: absolute;
        bottom: 100%;
        right: 0;
        background: #F2F2F2;
        border-radius: 4px;
        margin-bottom: 6px;
        padding: 0 15px;
        .floor-info-popup {
          padding: 10px 1px;
          background: $white;
          border-radius: 0 0 4px 4px;
          margin: 0 -15px;
          span {
            font-size: 14px;
            line-height: 17px;
            color: $black;
            font-weight: $light;
            @media only screen and (max-width: 1200px) {
              font-size: 13px;
            }
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;
              margin-top: -3px;
            }
            &.space-details {
              color: #8E8E8E;
              letter-spacing: -0.34px;
            }
            &.space-not-added {
              color: #8E8E8E;
              letter-spacing: -0.34px;
            }
            &.space-added {
              color: #4CC66D;
              letter-spacing: -0.34px;
            }
            b {
              font-weight: $bold;
              font-size: 14px;
              @media only screen and (max-width: 1200px) {
                font-size: 13px;
              }
            }
          }
        }
        .btn-green, .btn-red, .btn-green-opacity {
          width: 100%;
          margin-top: 10px;
        }
        .spaces-selected-info {
          padding: 16px 1px;
          .list-info {
            font-size: 14px;
            line-height: 17px;
            color: $black;
            font-weight: $regular;
            @media only screen and (max-width: 1200px) {
              font-size: 13px;
            }
            span {
              color: #A7A7A7;
            }
          }
          .close-drop-down {
            font-size: 14px;
            line-height: 17px;
            color: rgba(0,0,0,0.4);
            font-weight: $regular;
            letter-spacing: -0.34px;
            @media only screen and (max-width: 1200px) {
              font-size: 13px;
            }
            b {
              display: inline-block;
              vertical-align: middle;
              margin-left: 5px;
              color: $black;
              font-size: 18px;
              margin-top: -1px;
            }
          }
        }
        .row-margin-bottom-12px {
          margin-bottom: 12px;
        }
        .scroll-y {
          overflow-x: hidden;
          overflow-y: auto;
          max-height: calc(100vh - 365px);
        }
        .add-content {
          .background-space-white {
            background: $white;
            padding: 12px 16px;
            border-radius: 4px;
            margin-top: 12px;
            &:first-child {
              margin-top: 0;
            }
            span {
              font-size: 14px;
              line-height: 17px;
              color: #272626;
              font-weight: $regular;
              @media only screen and (max-width: 1200px) {
                font-size: 13px;
              }
              b {
                font-size: 14px;
                line-height: 17px;
                font-weight: $bold;
                @media only screen and (max-width: 1200px) {
                  font-size: 13px;
                }
              }
              &.result-sqm {
                margin-right: 16px;
              }
              &.result-sqm, &.result-max-people {
                font-weight: $light;
              }
            }
            .floor-number {
              .nr-cube {
                margin-right: 0;
                margin-left: 5px;
              }
            }
          }
        }
      }
      .nr-cube {
        width: 22px;
        height: 22px;
        line-height: 24px !important;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        background: $black;
        color: $white !important;
        border-radius: 4px;
        margin-top: -2px;
        margin-right: 5px;
        font-weight: $bold !important;
        @media only screen and (max-width: 1200px) {
          font-size: 13px;
        }
        &.nr-cube-white {
          color: $black !important;
          background: $white;
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      .space-combined {
        left: 50%;
        width: 360px;
        @include prefix(transform, translateX(-50%), ('webkit', 'moz', 'ms', 'o'));
        &.active {
          bottom: 5px;
        }
        p {
          width: 50%;
          text-align: left;
          float: left;
          padding: 4px 10px 3px 0;
          margin: 0;
        }
        a {
          width: 50%;
          float: left;
        }
      }
      .hidden-mobile-elements {
        display: none !important;
      }
      .hidden-desktop-elements {
        display: block !important;
      }
      .mobile-text-left {
        text-align: left !important;
      }
      .general-elements-style {
        .buttons-right {
          top: initial;
          right: initial;
          bottom: -100px;
          left: 50%;
          border-radius: 10px;
          @include prefix(transform, translateX(-50%), ('webkit', 'moz', 'ms', 'o'));
          &.active {
            right: initial;
            bottom: 119px;
          }
          .buttons-3D {
            white-space: nowrap;
          }
          .button-tooltip {
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
            margin-bottom: 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .legends-space {
          right: -183px;
        }
      }
      .section-spaces.canvas-buttons-wrapper {
        margin-left: -88px;
        z-index: 99;
        &.active {
          bottom: 45px;
        }
        .select-style {
          select {
            width: 130px;
          }
          label {
            padding: 5px 12.7px;
          }
        }
        .select2-container {
          min-width: 130px;
          margin: 0;
          .select2-selection--single {
            width: 130px;
            .select2-selection__arrow {
              height: 35px;
              width: 35px;
            }
          }
          &:hover {
            .select2-selection--single {
              background: $white;
              .select2-selection__arrow {
                b {
                  border-color: transparent transparent $black transparent;
                }
              }
            }
          }
        }
        .info-fit-out {
          .fit-out-text, .info-icon {
            z-index: 9999;
            font-size: 13px;
          }
        }
      }
      .cart-spaces {
        width: 170px;
        height: 66px;
        right: 50%;
        bottom: -100px;
        margin-right: -90px;
        @include prefix(transform, translateX(50%), ('webkit', 'moz', 'ms', 'o'));

        &.active {
          bottom: 45px
        }
        .all-spaces-selected {
          width: 351px;
          .scroll-y {
            max-height: calc(100vh - 470px);
          }
        }
        .floor-info {
          span {
            font-size: 13px;
            b {
              font-size: 13px;
            }
          }
          .space-details {
            color: #2BB751;
          }
        }
      }
    }
  }
  .select2-container {
    .fitout-dropdown-list {
      @media only screen and (max-width: 1200px) {
        width: 351px !important;
        margin-left: -45px;
        max-width: 351px;
      }
      &.select2-dropdown--above {
        bottom: 8px;
      }
      &.select2-dropdown--below {
        top: 8px;
      }
      .select2-results {
        .select2-results__options {
          border: none;
          border-radius: 4px;
          padding: 8px;
          max-height: 330px;
          background: #fff;
          .select2-results__option {
            font-size: 14px;
            line-height: 22px;
            border-radius: 4px;
            padding: 12px !important;
            margin-bottom: 7px;
            text-transform: initial;
            font-weight: $regular;
            max-width: 304px;
            @media only screen and (max-width: 1200px) {
              max-width: 357px;
              font-size: 13px;
            }
            &[aria-disabled="true"] {
              background: #F7F7F7;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &.select2-results__option[aria-selected=true], &.select2-results__option--highlighted[aria-selected] {
              color: $black;
            }
            &.select2-results__option[aria-selected=true] {
              background: #E3E3E3;
            }
            &:hover {
              background: #F7F7F7;
              color: $black;
            }
          }
        }
      }
    }
  }
</style>