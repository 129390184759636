import { LoadingManager } from "@/components/organisms/project/building/store/3d/managers/LoadingManager";
import { CameraBuilder } from "@/components/organisms/project/building/store/3d/builders/CameraBuilder";
import { InteractionManager } from "@/components/organisms/project/building/store/3d/managers/InteractionManager";
import { AnimationBuilder } from "@/components/organisms/project/building/store/3d/builders/AnimationBuilder";
import { HighlightManager } from "@/components/organisms/project/building/store/3d/managers/HighlightManager";
import { CameraManager } from "@/components/organisms/project/building/store/3d/managers/CameraManager";
import { EventManager } from "@/components/organisms/project/building/store/3d/managers/EventManager";
import { PinManager } from "@/components/organisms/project/building/store/3d/managers/PinManager";
import { PointerCircle } from "@/components/organisms/project/building/store/3d/builders/PointerCircle";
import { PinContentManager } from "@/components/organisms/project/building/store/3d/builders/PinContentManager";
import { isDesktop, isIOSDevice } from "@/helpers/mobile/DeviceType";
import { MinimapViewport } from "@/components/organisms/project/building/store/3d/builders/MinimapViewport";

export type PinInfo = {
  spaceCode: string;
  location: { 0: number; 1: number; 2: number };
  pinDescription: string;
  pinTitle: string;
  scale: {
    desktop: { 0: number; 1: number; 2: number };
    mobile: { 0: number; 1: number; 2: number };
  };
  imageSrc?: string;
  videoSrc?: string;
  floor?: string;
  metadata: Record<string, unknown>;
};

export class SceneManager {
  yOffset = 0;
  disabledNodes: Record<string, string> = {};
  private engine?: BABYLON.Engine;
  private scene?: BABYLON.Scene;
  private loadingManager?: LoadingManager;
  private cameraBuilder: CameraBuilder = new CameraBuilder();
  private interactionManager?: InteractionManager;
  private camera3DView?: BABYLON.ArcRotateCamera;
  private cameraFirstPerson?: BABYLON.UniversalCamera;
  private highlightManager: HighlightManager = new HighlightManager();
  private animationBuilder: AnimationBuilder = new AnimationBuilder();
  private cameraManager: CameraManager = new CameraManager(
    this.animationBuilder
  );
  private eventManager: EventManager = new EventManager(this.cameraManager);
  private pinManager?: PinManager;
  private pinContentManager?: PinContentManager;
  private pointerCircle?: PointerCircle;
  private minimap?: MinimapViewport;
  private checkUrlWorker?: Worker | null;

  init(canvasContainer: HTMLCanvasElement) {
    //// Create engine & scene
    this.engine = new BABYLON.Engine(canvasContainer, true, {
        preserveDrawingBuffer: isIOSDevice() ? false : true,
        stencil: isIOSDevice() ? false : true,
        powerPreference: isIOSDevice() ? 'low-power' : 'default',
        useHighPrecisionFloats: isIOSDevice() ? false : true,
        useHighPrecisionMatrix: isIOSDevice() ? false : true,
      },
      isIOSDevice() ? false : true
    );

    if (isIOSDevice()) {
      this.engine.enableOfflineSupport = false;
      this.engine.disableUniformBuffers = true;
      this.engine.getCaps().maxMSAASamples = 4;
      this.engine.getCaps().highPrecisionShaderSupported = false
    }
    this.scene = new BABYLON.Scene(this.engine);
    this.loadingManager = new LoadingManager(this.scene);
    //// Add controls
    this.interactionManager = new InteractionManager(this.engine);
    //// Optimize scene
    this.scene.skipPointerMovePicking = true;
    this.checkUrlWorker = this.createWorker();
    this.scene.freezeActiveMeshes();
    this.scene.freezeMaterials();

    if (isIOSDevice()) {
      this.scene.clearCachedVertexData();
      this.scene.cleanCachedTextureBuffer();
    }
  }

  initPinManager(cdnBase: string, store: any) {
    if (!this.scene || !this.interactionManager || !this.engine)
      return console.error("Scene not defined");
    if (this.pinContentManager) this.pinContentManager.dispose();
    this.pinContentManager = new PinContentManager(
      cdnBase,
      this.engine,
      this.scene,
      store
    );
    //// Add pin manager
    this.pinManager = new PinManager(this.pinContentManager);
    //// Add store to the highlight manager
    this.highlightManager.store = store;
  }

  addPins(
    pinData: PinInfo[],
    pinType: string,
    floor: string,
    building: string,
    layerMask: number
  ) {
    if (!this.scene || !this.pinManager || !this.interactionManager)
      return console.error("Pin Manager not defined");
    for (const pinInfo of pinData) {
      if (pinInfo.floor == floor || floor == "") {
        const pin = this.pinManager.addPin(
          1,
          this.scene,
          {
            text: pinInfo.pinDescription,
            title: pinInfo.pinTitle,
            imageSrc: pinInfo.imageSrc,
            videoSrc: pinInfo.videoSrc,
          },
          pinType,
          {
            floor: "floor" + pinInfo.floor,
            building: building,
            spaceCode: pinInfo.spaceCode,
          }
        );
        //// Flip existing positions to map blender scene position with babylon scene position
        pin.setPosition(
          pinInfo.location["0"] * -1,
          pinInfo.location["1"],
          pinInfo.location["2"] * -1
        );
        if (isDesktop()) {
          pin.setScale(pinInfo.scale.desktop["0"]);
        } else {
          pin.setScale(pinInfo.scale.mobile["0"]);
        }
        pin.pinMesh.layerMask = layerMask;
      }
    }
  }

  addArcRotateCamera(
    alpha: number,
    beta: number,
    radius: number,
    target: { x: number; y: number; z: number }
  ) {
    if (!this.scene || !this.interactionManager)
      throw Error("Scene or Interaction Manager not defined");
    this.camera3DView = this.cameraBuilder.buildArcRotateCamera(
      "ArcRotateCamera",
      alpha,
      beta,
      radius,
      new BABYLON.Vector3(target.x, target.y, target.z),
      this.scene
    );
    //// Default rotation controls
    this.interactionManager.addDefaultRotationControls(this.camera3DView);
    this.scene.activeCameras = [];
    this.scene.activeCameras?.push(this.camera3DView);
  }

  addUnivesalCamera(
    startPosition: { x: number; y: number; z: number },
    target: { x: number; y: number; z: number }
  ) {
    this.yOffset = startPosition.y;
    if (!this.scene || !this.interactionManager)
      throw Error("Scene or Interaction Manager not defined");
    this.cameraFirstPerson = this.cameraBuilder.buildUniversalCamera(
      "UniversalCamera",
      new BABYLON.Vector3(startPosition.x, startPosition.y, startPosition.z),
      this.scene
    );
    this.cameraFirstPerson.metadata = {};
    this.cameraFirstPerson.metadata.y = startPosition.y;
    this.cameraFirstPerson.setTarget(
      new BABYLON.Vector3(target.x, target.y, target.z)
    );
  }

  startRendering(self: SceneManager) {
    if (!self.engine || !self.scene) throw Error("Engine not defined");
    self.engine.runRenderLoop(() => {
      self.scene?.render();
    });
  }

  load(
    taskName: string,
    url: string,
    sceneFilename: string,
    onLoad: () => void,
    activeMeshes: string[]
  ) {
    if (!this.loadingManager)
      return console.error("Loading manager not defined");
    const optimizeEvents = activeMeshes.length != 0;
    this.loadingManager.addMeshLoadTask(
      taskName,
      "",
      url,
      sceneFilename,
      optimizeEvents,
      () => {
        if (!this.scene || !this.interactionManager)
          return console.error("Scene not defined");
        if (activeMeshes)
          this.interactionManager.addNodesToPointer(
            activeMeshes,
            this.scene,
            true,
            true
          );
        //// Render scene
        onLoad();
        // this.scene.debugLayer.show();
        //// Execute after scene has fully loaded
        this.scene.executeWhenReady(() => {
          if (!this.scene) return;
          //// Reposition the intial tooltip
          this.highlightManager.repositionToolTip(this.scene);

          if (this.isDebuggerInQuery()) {
            for (const material of this.scene.materials) {
              material.unfreeze();
            }
          }

          this.checkUrlWorker?.terminate();
          this.checkUrlWorker = null;
        });
      }
    );
  }

  isDebuggerInQuery() {
    if (typeof window === "undefined") return false;

    // Assuming the query string is from the current page's URL
    const queryString = window.location.search;

    // Create a URLSearchParams instance with the query string
    const params = new URLSearchParams(queryString);

    // Check if 'debug' is present and its value is 'true'
    return params.get("v") === "debug";
  }

  async fileExistsWithWorker(url: string) {
    let fileExist = true;
    if (this.checkUrlWorker) {
      const channel = new MessageChannel();

      this.checkUrlWorker.postMessage({ url, port: channel.port2 }, [
        channel.port2,
      ]);

      fileExist = await new Promise((resolve) => {
        channel.port1.onmessage = (e) => {
          resolve(e.data);
        };
      });
    } else {
      fileExist = await this.fileExists(url);
    }

    return fileExist;
  }

  async fileExists(url: string) {
    try {
      const response = await fetch(url, { method: "HEAD" });
      return response.status === 200;
    } catch (error) {
      console.error("There was an error checking the file:", error);
      return false;
    }
  }

  createWorker() {
    const inlineWorker = `
    self.onmessage = async (e) => {
      const { url, port } = e.data;

      async function exist(url) {
        try {
          const response = await fetch(url, { method: "HEAD" });
          return response.status === 200;
        } catch (error) {
          console.error("There was an error checking the file:", error);
          return false;
        }
      }

      const result = await exist(url);
      port.postMessage(result);
      port.close();
    }`;

    const url = URL.createObjectURL(
      new Blob([inlineWorker], { type: "text/javascript" })
    );
    return new Worker(url);
  }

  loadHDR(url: string) {
    if (!this.loadingManager || !this.scene)
      throw Error("Loading Manager or Scene not defined");
    this.loadingManager.loadHDR(url, this.scene);
  }

  clearScene() {
    if (!this.scene || !this.engine) {
      console.warn("Scene or Engine not defined");
      return;
    }
    this.cameraMoved();
    //// Dispose of current scene
    this.scene.dispose();
    //// Initialize new scene
    this.scene = new BABYLON.Scene(this.engine);
    //// Create new loading manager for the new scene
    delete this.loadingManager;
    this.loadingManager = new LoadingManager(this.scene);
    //// Dispose of Pins
    this.pinContentManager?.dispose();
    //// Dispose of highlight tooltip
    this.highlightManager.removeHighlightTooltip();
    this.highlightManager.removeSelectedTooltip();
    this.camera3DView?.onViewMatrixChangedObservable.clear();
    this.cameraFirstPerson?.onViewMatrixChangedObservable.clear();
  }

  destroy() {
    if (!this.scene || !this.engine) {
      console.error("Scene or Engine not defined");
      return;
    }
    this.clearScene();
    delete this.loadingManager;
    delete this.interactionManager;
    this.scene.dispose();
    this.engine.dispose();
    this.eventManager.dispose();
  }

  setSceneProperties(options: unknown) {
    Object.assign(this.scene, options);
  }

  setPointerY(yNodeName: string) {
    const floor = this.scene?.getNodeByName(yNodeName);
    const y = (floor as BABYLON.TransformNode).position.y;
    if (!this.pointerCircle) return console.error("y position not found");
    this.pointerCircle.yPos = y + 0.1;
  }

  disableNode(nodeName: string, prefix: string) {
    if (!this.scene) throw Error("Scene not defined");
    const node = this.scene.getTransformNodeByName(nodeName);
    if (!node) throw Error("Node not defined");
    this.disabledNodes[prefix] = nodeName;
    this.interactionManager?.enableNodes(
      [nodeName],
      this.scene,
      false,
      false,
      false
    );
  }

  setNodesMask(nodeNames: string[], maskLayer: number) {
    if (!this.scene) throw Error("Scene not defined");
    this.interactionManager?.parseNodes(nodeNames, this.scene, {
      layerMask: maskLayer,
    });
  }

  addLight() {
    if (!this.scene) return console.error("Scene is not defined");
    const light = new BABYLON.HemisphericLight(
      "HemiLight",
      new BABYLON.Vector3(0, 1, 0),
      this.scene
    );
    light.intensity = 0.5;
  }

  resize() {
    if (!this.engine) return console.error("Engine not defined");
    this.engine.resize();
    if (!this.minimap) return console.error("Minimap not defined");
    this.minimap.resizeMinimap();
  }

  addVirtualTourControls() {
    if (
      !this.cameraFirstPerson ||
      !this.interactionManager ||
      !this.engine ||
      !this.scene ||
      !this.pointerCircle ||
      !this.minimap
    )
      return console.error("First person camera not defined");
    this.interactionManager.addVirtualTourControls(this.cameraFirstPerson, {});
    this.eventManager.addVirtualTourCameraEvents(
      [this.cameraFirstPerson, this.minimap.camera],
      this.scene
    );
  }

  set3dCameraViewProperties(options: unknown) {
    if (!this.camera3DView)
      return console.error("Camera 3d view is not defined");
    Object.assign(this.camera3DView, options);
  }

  changeCameraTargetScreenOffset(x?: number, y?: number) {
    if (!this.camera3DView)
      return console.error("Camera 3d view is not defined");

    const sceneUnitX = this.convertPixelsToSceneUnits(x || 0);
    const sceneUnitY = this.convertPixelsToSceneUnits(y || 0);

    this.camera3DView.targetScreenOffset = new BABYLON.Vector2(
      sceneUnitX,
      sceneUnitY
    );
  }

  convertPixelsToSceneUnits(pixels: number, customRadius = 0) {
    if (!this.camera3DView) return 0;
    if (!this.engine) return;

    const canvas = this.engine.getRenderingCanvas();
    if (!canvas) return 0;
    const camera = this.camera3DView;

    const fov = camera.fov;
    const cameraRadius = customRadius !== 0 ? customRadius : camera.radius;
    const horizontalFOV = 2 * cameraRadius * Math.tan(fov / 2);
    const screenSpaceOffset = (pixels / canvas.clientWidth) * horizontalFOV;

    return screenSpaceOffset;
  }

  set3dCameraTarget(target: { x: number; y: number; z: number }) {
    if (!this.camera3DView)
      return console.error("Camera 3d view is not defined");
    this.camera3DView.setTarget(
      new BABYLON.Vector3(target.x, target.y, target.z)
    );

    this.cameraManager.defaultTarget = target;
  }

  setVirtualTourProperties(options: unknown) {
    if (!this.cameraFirstPerson)
      return console.error("Virtual tour camera view is not defined");
    Object.assign(this.cameraFirstPerson, options);
  }

  setZoomLimit(zoomLimit: number) {
    this.cameraManager.cameraRadiusLimit = zoomLimit;
  }

  addPointerCircle() {
    if (!this.scene) return console.error("Scene not defined");
    this.pointerCircle = new PointerCircle();
    this.pointerCircle.init(this.scene, "virtualTourPointer", 0x10000000);
  }

  switchCamera(view: string) {
    if (
      !this.camera3DView ||
      !this.scene ||
      !this.pinManager ||
      !this.pinContentManager ||
      !this.interactionManager ||
      !this.cameraFirstPerson ||
      !this.minimap
    )
      return console.error("3D Camera is not defined");
    this.pinContentManager.dispose();
    this.cameraMoved();
    this.pointerCircleVisible = this.pointerCircleVisible.bind(this);
    this.cameraManager.switchCamera(
      view,
      this.pinManager,
      this.highlightManager,
      this.scene,
      this.interactionManager,
      this.cameraFirstPerson,
      this.camera3DView,
      this.minimap,
      this.disabledNodes,
      this.pointerCircleVisible
    );
  }

  addBuildingEvents() {
    if (!this.scene) return console.error("Scene not defined");
    this.eventManager.addPointerMoveEvents(this.scene, [
      {
        funct: this.eventManager.pinEvent,
        options: {
          scene: this.scene,
          animationBuilder: this.animationBuilder,
        },
      },
    ]);
    this.eventManager.addPointerDownEvents(this.scene, [
      {
        funct: this.eventManager.pinOpenEvent,
        options: {
          scene: this.scene,
          animationBuilder: this.animationBuilder,
          pinEvent: this.eventManager.pinEvent,
        },
      },
    ]);
    this.eventManager.addPointerUpEvents(this.scene, []);
  }

  addFloorEvents() {
    if (!this.scene || !this.minimap) return console.error("Scene not defined");
    this.eventManager.addPointerMoveEvents(this.scene, [
      {
        funct: this.eventManager.pinEvent,
        options: {
          scene: this.scene,
          animationBuilder: this.animationBuilder,
        },
      },
      {
        funct: this.eventManager.vtPointerEvent,
        options: {
          pointerCircle: this.pointerCircle,
          minimapPointerCircle: this.minimap.minimapPointer,
          pointerY: 25,
        },
      },
      {
        funct: this.eventManager.floorHighlightEvent,
        options: {
          highlightManager: this.highlightManager,
          scene: this.scene,
        },
      },
    ]);
    this.eventManager.addPointerDownEvents(this.scene, [
      {
        funct: this.eventManager.pinOpenEvent,
        options: {
          scene: this.scene,
          animationBuilder: this.animationBuilder,
          pinEvent: this.eventManager.pinEvent,
        },
      },
    ]);
    this.eventManager.addPointerUpEvents(this.scene, [
      {
        funct: this.eventManager.floorHighlightSelect,
        options: {
          highlightManager: this.highlightManager,
          scene: this.scene,
        },
      },
    ]);
  }

  setVisibility(objects: string[], visibility: number) {
    if (!this.interactionManager || !this.scene)
      return console.error("Scene not defined");
    this.interactionManager.setVisibilityOfNodes(
      objects,
      this.scene,
      visibility
    );
  }

  cameraMoved() {
    if (!this.pinContentManager || !this.pinManager || !this.scene)
      return console.error("Pin managers not defined");
    this.pinManager.resetPins(this.scene, this.animationBuilder);
  }

  setZoomValue(val: number) {
    if (!this.cameraManager || !this.camera3DView)
      return console.error("Arc rotate camera is not defined");
    this.cameraManager.zoomCamera(val, this.camera3DView, 4);
  }

  pointerCircleVisible(isVisible: boolean) {
    if (!this.pointerCircle?.mesh)
      return console.error("Pointer Circle has no mesh");
    this.pointerCircle.mesh.isVisible = isVisible;
  }

  setNodesInteraction(nodes: string[]) {
    if (!this.interactionManager || !this.scene)
      return console.error("Scene not defined");
    this.interactionManager.enableNodes(nodes, this.scene, true, true, true);
  }

  resetPointer() {
    if (!this.scene) return console.error("Scene not defined");
    //// Reset scene Pointer
    this.scene.pointerY = 0;
    this.scene.pointerX = 0;
  }

  initMinimap(floorName: string) {
    if (!this.scene || !this.engine || !this.camera3DView)
      return console.error("Camera 3D view not defined");
    this.minimap = new MinimapViewport(this.scene, floorName);
  }

  setCookieFunctions(
    addCookieHoverSpace: () => void,
    addCookieClickSpace: () => void
  ) {
    this.highlightManager.addCookieHoverSpace = addCookieHoverSpace;
    this.highlightManager.addCookieClickSpace = addCookieClickSpace;
  }

  highlightSpace(spaceId: string | null) {
    if (!this.scene) return console.error("Scene not defined");
    if (spaceId == null) {
      this.highlightManager.hideMesh(true);
      return;
    }
    const highlightsNode = this.scene.getNodeByName("highlights");
    if (!highlightsNode) return console.error("Highlights node not defined");
    const mesh = this.highlightManager.findMeshById(
      spaceId,
      highlightsNode as BABYLON.TransformNode
    );
    if (!mesh)
      return console.error("Highlight space " + spaceId + " not found");
    const hasTooltip =
      this.scene.activeCameras?.[0].name != "UniversalCamera" || false;
    this.highlightManager.selectMesh(
      mesh as BABYLON.Mesh,
      this.scene,
      false,
      hasTooltip
    );
    this.highlightManager.repositionToolTip(this.scene);
  }

  moveCameraToArea(
    target: { x: number; y: number; z: number },
    lookAt: { x: number; y: number; z: number }
  ) {
    if (!this.scene || !this.cameraFirstPerson)
      return console.error("Scene not defined");
    this.cameraManager.moveUniversalCamera(
      this.cameraFirstPerson,
      this.scene,
      target,
      () => {
        this.cameraFirstPerson?.setTarget(
          new BABYLON.Vector3(lookAt.x, lookAt.y, lookAt.z)
        );
      }
    );
  }

  add3DCameraObservables() {
    if (!this.camera3DView) return console.error("Cameras not defined");
    this.camera3DView.onViewMatrixChangedObservable.add(() => {
      this.cameraMoved();
      if (this.highlightManager.selectedMesh && this.scene) {
        this.highlightManager.repositionToolTip(this.scene);
        this.highlightManager.hideMesh(false);
      }
    });
  }

  addFirstPersonCameraObservables() {
    if (!this.cameraFirstPerson) return console.error("Cameras not defined");
    //// After each camera input change render the scene
    this.cameraFirstPerson.onViewMatrixChangedObservable.add(() => {
      if (this.cameraFirstPerson) {
        this.cameraFirstPerson.position.y = this.cameraFirstPerson.metadata.y;
        this.cameraMoved();
        //// Update minimap pointer
        this.minimap?.updatePositions(
          this.cameraFirstPerson.position,
          this.cameraFirstPerson.rotation.y
        );
      }
    });
  }

  setHighlightsIsPublic(spaces: Array<any>, building: string, floor: string) {
    if (!this.scene || !this.interactionManager)
      return console.error("Scene not defined");
    for (const space of spaces) {
      const hasMultipleSpacesWithSameSpaceCode =
        spaces.filter((sp) => sp.space_code === space.space_code).length > 1;
      if (space.is_public == 0 && !hasMultipleSpacesWithSameSpaceCode) {
        this.scene
          .getNodeByName(
            "hl_" + building + "_" + floor + "_" + space.space_code
          )
          ?.setEnabled(false);
      }
    }
  }

  updateLightmapFromAO() {
    if (!this.scene) return;
    for (const material of this.scene.materials) {
      const mat = material as BABYLON.PBRMaterial;
      if (mat.ambientTexture) {
        mat.unfreeze();
        mat.lightmapTexture = mat.ambientTexture;
        mat.useLightmapAsShadowmap = true;
        mat.ambientTexture = null;
      }

      if (mat.name.includes("shadow") || mat.name.includes("Shadow")) {
        mat.unfreeze();
        mat.alphaMode = BABYLON.Engine.ALPHA_MULTIPLY;
      }

      if (mat.transparencyMode !== 0) {
        mat.unfreeze();
        mat.separateCullingPass = true;
      }

      setTimeout(() => {
        mat.freeze();
      }, 100);
    }
  }

  applyImageProcessing(imageProcessingProperties: any) {
    if (!this.scene || !imageProcessingProperties) {
      console.error("Scene not defined");
      return;
    }

    if (imageProcessingProperties.toneMapping === 0) {
      this.scene.imageProcessingConfiguration.toneMappingEnabled = false;
    } else {
      this.scene.imageProcessingConfiguration.toneMappingEnabled = true;
      this.scene.imageProcessingConfiguration.toneMappingType =
        imageProcessingProperties.toneMapping - 1;
    }

    this.scene.imageProcessingConfiguration.contrast =
      imageProcessingProperties.contrast;
    this.scene.imageProcessingConfiguration.exposure =
      imageProcessingProperties.exposure;
    this.scene.environmentIntensity = imageProcessingProperties.iblIntensity;
  }
}

const BabylonSceneManager = new SceneManager();
export { BabylonSceneManager };
