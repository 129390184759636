<template>
  <div :class="{section: true, 'section-contact': true, 'section-contact-v2': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismContactForm/>
    <div class="row contacts-wrapper align-items-center" v-if="!data.contactFormOnly">
      <div class="col col-xs-12 col-sm-12 col-6 text">
        <div class="title" v-html="data.title"></div>
        <div class="subtitle" v-html="data.subtitle"></div>
        <div class="social">
          <div v-if="data.socialTitle" class="title" v-html="data.socialTitle"></div>
          <a v-if="data.instagramUrl" :href="data.instagramUrl" target="_blank">
            <AtomIcon icon="instagram" :size="40" color="#FFF"/>
          </a>
          <a v-if="data.linkedinUrl" :href="data.linkedinUrl" target="_blank">
            <AtomIcon icon="linkedin-squared" :size="40" color="#FFF"/>
          </a>
          <a v-if="data.facebookUrl" :href="data.facebookUrl" target="_blank">
            <AtomIcon icon="facebook-squared" :size="40" color="#FFF"/>
          </a>
        </div>
      </div>
      <AtomContact class="col col-xs-12 col-sm-6 col-3" v-for="(member, index) in data.contacts" :image="member.image" :name="member.name"
                   :urlLinkedin="member.urlLinkedin" :nr="member.nr" :position="member.position" :email="member.email"
                   :key="index"/>
    </div>
  </div>
</template>

<script>
import OrganismContactForm from "../../../components/organisms/project/OrganismContactForm";
import AtomContact from "../../../components/atoms/portfolio/AtomContact";
import AtomIcon from "../../../components/atoms/common/AtomIcon";

export default {
  name: "SectionContact",
  components: {AtomIcon,  AtomContact, OrganismContactForm},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.general
    },
    instagramUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.facebookUrl;
    }
  }
}
</script>

<style lang="scss">
.section-contact-v2 {
  position: relative;
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .organism-contact-form {
    padding: 7.5rem 3.75rem 8.125rem;
    @media only screen and (max-width: 900px) {
      padding: 1.25rem 0.625rem 1.875rem;
    }
    .atom-title {
      margin-bottom: 4rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.875rem;
      }
      h2 {
        text-transform: none;
        @include font-main(3.25rem, $black !important, $regular, 3.875rem);
        @media only screen and (max-width: 767px) {
          @include font-main(
                  1.375rem,
                  $black !important,
                  $regular,
                  1.625rem
          );
        }
      }
      h3 {
        @include font-main(
                1.375rem,
                $black !important,
                $regular,
                1.625rem
        );
        @media only screen and (max-width: 767px) {
          @include font-main(
                  0.875rem,
                  $black !important,
                  $regular,
                  1.188rem
          );
        }
      }
    }
    .input-atom-wrapper {
      input,
      textarea {
        background: #f5f9fb;
        @include font-main(0.875rem, #7790aa, $regular, 1.063rem);
        @media only screen and (max-width: 767px) {
          @include font-main(0.875rem, #7790aa, $regular, 1.063rem);
        }
        &::-webkit-input-placeholder {
          color: #7790aa;
        }
        &:-ms-input-placeholder {
          color: #7790aa;
        }
        &::placeholder {
          color: #7790aa;
        }
      }
    }
    .button-atom {
      background: transparent;
      padding: 0.75rem 2rem;
      font-weight: $regular;
      border-color: $main-color;
      @media only screen and (max-width: 767px) {
        padding: 0.5rem 0.938rem;
      }
      span {
        color: $black;
        text-transform: none;
      }
      &:hover {
        background: $main-color;
        span {
          color: $white;
        }
      }
    }
  }

  .atom-contact {
    margin-bottom: 3.125rem;
    .atom-gradient-background {
      padding: 0 0.375rem;
      background: none;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
      .content-info {
        background: rgba(0, 0, 0, 0.2);
        padding: 1.25rem 1.5rem;
        @media only screen and (max-width: 767px) {
          padding: 0.938rem 1.25rem;
        }
        .col {
          padding: 0;
          margin: 0;
          &.col-7 {
            flex-basis: calc(100% / 12 * 12) !important;
          }
          span {
            display: block;
            width: 100%;
            text-transform: none;
            margin-bottom: 1.125rem;
            @include font-main(1rem, $white, $regular, 1.375rem);
            @media only screen and (max-width: 767px) {
              margin-bottom: 0.938rem;
              @include font-main(0.875rem, $white, $regular, 1.188rem);
            }
          }
          .name {
            margin-bottom: 0.25rem;
            max-width: 100%;
            @include font-main(1.375rem, $white, $regular, 1.625rem);
            @media only screen and (max-width: 767px) {
              @include font-main(1.25rem, $white, $regular, 1.5rem);
            }
          }
          a {
            @include font-main(1rem, $white, $regular, 1.188rem);
            @media only screen and (max-width: 767px) {
              @include font-main(0.875rem, $white, $regular, 1.188rem);
            }
          }
        }
      }
    }
  }

  .contacts-wrapper {
    background-color: $main-color;
    padding: 4.813rem 3.75rem 4.688rem;
    margin: 0 -0.625rem;
    @media only screen and (max-width: 900px) {
      padding: 1.875rem 1.25rem 0;
      margin: 0 -0.625rem;
    }
    .text {
      color: $white;
      padding: 0 0.375rem;
      margin: 3rem 0;
      @media only screen and (max-width: 767px) {
        padding: 0;
        margin: 0;
      }
      .title {
        max-width: 36.375rem;
        margin-bottom: 1.25rem;
        @include font-main(3.25rem, $white, $regular, 3.875rem);
        @media only screen and (max-width: 767px) {
          max-width: 100%;
          margin-bottom: 0.938rem;
          @include font-main(1.375rem, $white, $regular, 1.625rem);
        }
        span {
          @include font-main(3.25rem, $white, $bold, 3.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(1.375rem, $white, $bold, 1.625rem);
          }
        }
      }

      .subtitle {
        margin-bottom: 5.313rem;
        @include font-main(1.375rem, $white, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 1.875rem;
          @include font-main(0.875rem, $white, $regular, 1.188rem);
        }
        a {
          font-weight: 400;
          font-size: 1.375rem;
          line-height: 1.625rem;
          color: $white;
          margin-top: 1.1rem;
          display: inline-block;
          text-decoration: underline !important;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 19px;
            margin-top: 10px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }

      .social {
        @media only screen and (max-width: 767px) {
          margin-bottom: 2.5rem;
        }
        .title {
          margin-bottom: 1.875rem;
          @include font-main(2rem, $white, $regular, 2.375rem);
          @media only screen and (max-width: 767px) {
            margin-bottom: 0.938rem;
            @include font-main(1.375rem, $white, $regular, 1.625rem);
          }
          span {
            @include font-main(2rem, $white, $bold, 2.375rem);
            @media only screen and (max-width: 767px) {
              @include font-main(1.375rem, $white, $bold, 1.625rem);
            }
          }
        }
        a {
          display: inline-block;
          margin-right: 1.5rem;
          @media only screen and (max-width: 767px) {
            margin-right: 0.75rem;
          }
          &:last-child {
            margin-right: 0;
          }
          .icon {
            font-size: 2.5rem !important;
            @media only screen and (max-width: 767px) {
              font-size: 1.25rem !important;
            }
            &:before {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>