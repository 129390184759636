var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-list-circle" },
    [
      _c(
        "MoleculeListCircle",
        _vm._l(_vm.listCircleData, function(list, index) {
          return _c("AtomListCircle", {
            key: index,
            attrs: { title: list.title, text: list.text }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }