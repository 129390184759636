
export class LoadingManager {
  private manager: BABYLON.AssetsManager;
  private meshTaskResults: Map<string, BABYLON.MeshAssetTask> = new Map();
  private textureTaskResults: Map<string, BABYLON.TextureAssetTask> = new Map();

  constructor(private readonly scene: BABYLON.Scene) {
    this.manager = new BABYLON.AssetsManager(scene);
    this.manager.useDefaultLoadingScreen = false;
  }

  addMeshLoadTask(
    taskName: string,
    meshNames: string,
    rootUrl: string,
    sceneFilename: string,
    optimizeEvents: boolean,
    onLoad: () => void,
  ) {
    const newTask = this.manager.addMeshTask(
      taskName,
      undefined,
      rootUrl,
      sceneFilename,
      ".gltf"
    );
    newTask.onSuccess = (task) => {
      //// disable picking on meshes
      if (optimizeEvents) {
        for (const mesh of task.loadedMeshes) {
          if (mesh.material) {
            mesh.material.freeze();
            mesh.isPickable = false;
            mesh.enablePointerMoveEvents = false;
            mesh.freezeWorldMatrix();
            mesh.layerMask = 0x10000000;
          }
        }
      }
      onLoad();
      this.meshTaskResults.set(task.name, task);
    };
    newTask.onError = (task, message, exception) => {
      console.error(task + "Task Failed");
      console.error(message);
      console.error(exception);
    };
  }

  loadHDR(url: string, scene: BABYLON.Scene) {
    const hdrImage = BABYLON.CubeTexture.CreateFromPrefilteredData(url, scene);
    hdrImage.gammaSpace = false;
    scene.environmentTexture = hdrImage;
  }

  getMeshTaskResults() {
    return this.meshTaskResults;
  }

  addTextureLoadTask(taskName: string, url: string) {
    const newTask = this.manager.addTextureTask(taskName, url);
    newTask.onSuccess = (task) => {
      this.textureTaskResults.set(task.name, task);
    };
    newTask.onError = (task) => {
      console.log(task);
      console.error("Task Failed");
    };
  }

  getTextureTaskResults() {
    return this.textureTaskResults;
  }

  startLoading() {
    return this.manager.load();
  }
}
