<template>
  <div class="organism-space-details">
    <OrganismDrawer
      ref="drawer"
      :default-open="defaultOpen"
      @drawer:close="$emit('drawer:close')"
    >
      <div class="main-content">
        <div class="title-box">
          <span class="title" v-if="space">{{
            space.alias || space.space_code
          }}</span>
          <span class="item-text" v-if="surface"
            ><AtomIcon icon="ruler-vertical" /> <b>{{ surface }}</b>
            {{ unitOfMeasureString }}</span
          >
        </div>
        <!--        <div class="info-box">-->
        <!--          <span class="item">-->
        <!--            <AtomIcon color="#000" :size="16" icon="buildings-alt"/>-->
        <!--            <span class="item-text">{{ building.code }}</span>-->
        <!--          </span>-->
        <!--          <span class="item">-->
        <!--            <AtomIcon color="#000" :size="16" icon="stairs"/>-->
        <!--            <span class="item-text" v-html="getMappedFloorText(space.floor.no)"></span>-->
        <!--          </span>-->
        <!--          <span class="item">-->
        <!--            <AtomIcon color="#000" :size="16" icon="ruler-vertical"/>-->
        <!--            <span class="item-text"-->
        <!--            >{{ surface }} </span>-->
        <!--          </span>-->
        <!--        </div>-->
        <div class="content-box" v-if="space">
          <div class="space-info">
            <div class="column-space-info">
              <span class="item-text">
                <AtomIcon color="#000" :size="16" icon="chair-office" />
                <b>{{ space.workplaces }}</b> <br />
                Workstations
              </span>
            </div>
            <div class="column-space-info">
              <span class="item-text">
                <AtomIcon color="#000" :size="16" icon="ruler-vertical" />
                <b>{{ surface }}</b> <br />
                {{ unitOfMeasureString }}
              </span>
            </div>
            <div class="column-space-info">
              <span class="item-text">
                <AtomIcon color="#000" :size="16" icon="stairs" />
                <b>{{ spaceFloor.no }}</b> <br />
                Floor
              </span>
            </div>
          </div>
          <!--          <div class="content-buttons" v-if="shortlistState">-->
          <!--            <AtomButton-->
          <!--                v-if="!requestOfferSpaces.find((sl) => sl.id === space.id)"-->
          <!--                class="shortlist-btn"-->
          <!--                icon="shortlist-icon"-->
          <!--                label="Shortlist Space"-->
          <!--                custom-label="`Add"-->
          <!--                :on-click="addToShortlist"-->
          <!--                activateHoverEvent-->
          <!--            />-->
          <!--            <AtomButton-->
          <!--                v-else-->
          <!--                class="remove-shortlist-btn"-->
          <!--                icon="remove-shortlist"-->
          <!--                label="Remove Space"-->
          <!--                custom-label="Remove"-->
          <!--                :on-click="removeFromShortlist"-->
          <!--                activateHoverEvent-->
          <!--            />-->
          <!--            <AtomButton-->
          <!--                class="request-offer-btn"-->
          <!--                label="`Request Offer"-->
          <!--                :on-click="() => openRequestOfferModal()"-->
          <!--            />-->
          <!--          </div>-->
          <div class="content-buttons" v-if="space && space.is_public">
            <AtomButton
              class="request-offer-btn button-green"
              label="Request Offer"
              custom-label="Request"
              icon="plane-paper"
              :on-click="() => openRequestOfferModal()"
              activate-hover-event
            />
          </div>
        </div>
      </div>
      <div class="surface-details">
        <span class="title">Space details</span>
        <div class="unit-wrapper">
          <span class="unit-title">{{ spaceName }}</span>
        </div>
        <div class="unit-wrapper">
          <span class="unit-title">Available space</span>
          <div>
            <span class="unit-surface"
              ><b>{{ surface }}</b></span
            >
            {{ unitOfMeasureString }}
          </div>
        </div>
        <div class="unit-wrapper">
          <span class="unit-title">{{ spaceWorkplacesName }}</span>
          <div>
            <span class="unit-surface"
              ><b>{{ spaceWorkplaces }}</b></span
            >
          </div>
        </div>
        <!--        <div-->
        <!--            class="unit-wrapper"-->
        <!--            v-for="unit in space.units"-->
        <!--            :key="`unit-${unit.id}`"-->
        <!--        >-->
        <!--          <span class="unit-title" v-if="unit.roomType">{{ unit.roomType.name }}</span>-->
        <!--          <span class="unit-title" v-else>{{ unit.title }}</span>-->
        <!--          <div>-->
        <!--            <span class="unit-surface">{{ unit.surface }}</span> sqm-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="floor-plan-button-wrapper" v-if="floorPlanDownloadActive">
        <a
          class="floor-plan-button"
          :href="`${resourceUrl}`"
          download="floorPlan.pdf"
          target="_blank"
        >
          <AtomButton class="floor-plan-button" label="Download Floor Plan" />
        </a>
      </div>
    </OrganismDrawer>
  </div>
</template>

<script>
import OrganismDrawer from "../OrganismDrawer";
import AtomButton from "../../../atoms/common/AtomButton";
import AtomIcon from "../../../atoms/common/AtomIcon";
import config from "@/config";

export default {
  name: "OrganismSpaceDetails",
  components: {
    AtomIcon,
    AtomButton,
    OrganismDrawer,
  },
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    floorPlanDownloadActive() {
      return !!this.$store.state.base.meta.general.floorPlanDownloadActive;
    },
    shortlistState() {
      return this.$store.getters?.getShortlist || false;
    },
    tabData() {
      const tabData = [];
      tabData.push({
        label: "Co-working",
        tabId: "coworking",
      });
      tabData.push({
        label: "Project Info",
        tabId: "project-info",
      });
      return tabData;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    project() {
      return this.$store.state.project?.projects?.find(
        (project) => project.slug === this.activeProject
      );
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    currentSpaceId() {
      return this.$store.state.space.currentSpaceId;
    },
    landlordDetails() {
      return this.$store.state.base.landlordDetails;
    },
    space() {
      const spaceId =
        this.currentSpaceId ||
        this.$route.params.spaceId ||
        this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot;
      return this.landlordDetails.spaces.find((s) => s.id === +spaceId);
    },
    building() {
      if (!this.space) return;
      return this.landlordDetails?.buildings?.find(
        (b) => b.id === this.space.building_id
      );
    },
    requestOfferSpaces() {
      return this.$store.state?.requestOffer?.spaces || [];
    },
    spaceFloor() {
      return (
        this.landlordDetails?.floors?.find(
          (f) => f.id === this.space.floor_id
        ) || null
      );
    },
    resourceUrl() {
      return `${this.$store.getters.cdnBase}/availability/${config.CLIENT}/${this.project.slug}/${this.building.code}/${this.spaceFloor.no}.pdf?amp;response-content-disposition=attachment`;
    },
    surface() {
      const s = this.space?.sqm || 0;
      if (s === 0) return;
      return `${s}`;
    },
    spaceName() {
      let spaceType = "Other Type";
      if (!this.space?.space_type) return spaceType;
      const spaceTypeName = this.space.space_type.name || "";
      if (spaceTypeName.toLowerCase().includes("office")) {
        spaceType = "Office";
      } else if (spaceTypeName === "Meeting room") {
        spaceType = "Meeting Room";
      } else if (spaceTypeName === "Open Space") {
        spaceType = "Open Space";
      }
      return spaceType;
    },
    spaceWorkplaces() {
      return this.space?.workplaces || 0;
    },
    spaceWorkplacesName() {
      if (this.spaceWorkplaces === 0) return `No workstations`;
      if (this.spaceWorkplaces === 1) return `Workstation`;
      if (this.spaceWorkplaces > 1) return `Workstations`;
    },
  },
  methods: {
    openRequestOfferModal() {
      const spaceData = {
        spaceId: this.space.space_code,
        building: this.building.code,
        floor: this.spaceFloor.code,
      };
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
        null,
        { root: true }
      );
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        spaceData
      );

      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        {
          showModal: true,
          modalType: "request-offer",
          data: { space: { ...this.space, building: this.building } },
        }
      );
      document.body.classList.add("disable-scroll");
    },
    // addToShortlist() {
    //   const requestOffer = this.$store.getters.constants.requestOffer;
    //   const universeConstants = this.$store.getters.constants.universe;
    //   this.$store.dispatch(universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX), {
    //     open: true
    //   });
    //   setTimeout(() => this.$store.dispatch(universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX), {
    //     open: false
    //   }), 5000);
    //   this.$store.dispatch(
    //       requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
    //       {
    //         ...this.space,
    //         building: this.building,
    //         floor: this.space.floor,
    //       }
    //   );
    // },
    // removeFromShortlist() {
    //   const requestOffer = this.$store.getters.constants.requestOffer;
    //   this.$store.dispatch(
    //       requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE),
    //       {
    //         ...this.space,
    //         building: this.building,
    //         floor: this.space.floor,
    //       }
    //   );
    // },
    // clearShortlist(space) {
    //   const requestOffer = this.$store.getters.constants.requestOffer;
    //   this.$store.dispatch(
    //       requestOffer.withNamespace(requestOffer.action.CLEAR_ALL),
    //       null
    //   );
    // },
    exploreSpace(spaceId) {
      const routePageData = this.$route.meta.page;
      this.$router.push({
        name: routePageData.isEmbedReady
          ? "SpecificSpaceEmbed"
          : "SpecificSpace",
        params: {
          projectId: this.activeProject,
          spaceId,
        },
      });
    },
    toggleDrawer() {
      this.$refs["drawer"]?.toggleFilter();
    },
    closeDrawer() {
      this.$refs["drawer"]?.close();
    },
    openDrawer() {
      this.$refs["drawer"]?.open();
    },
    handleExpandAvailability() {
      this.$emit("toggleAvailability");
    },
    getMappedFloorText(floor) {
      let text = "";
      switch (parseInt(floor, 10)) {
        case -3:
          text = `-3<sup class="superior-text">rd</sup>`;
          break;
        case -2:
          text = `-2<sup class="superior-text">nd</sup>`;
          break;
        case -1:
          text = `-1<sup class="superior-text">st</sup>`;
          break;
        case 0:
          text = "Ground";
          break;
        case 1:
          text = `1<sup class="superior-text">st</sup>`;
          break;
        case 2:
          text = `2<sup class="superior-text">nd</sup>`;
          break;
        case 3:
          text = `3<sup class="superior-text">rd</sup>`;
          break;
        default:
          text = `${floor}<sup class="superior-text">th</sup>`;
      }
      return text;
    },
  },
};
</script>

<style lang="scss">
.organism-space-details {
  z-index: 99999;

  .main-content {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 0.25rem;
    background: rgba(229, 234, 237, 1);
    padding: 1.188rem 0.875rem;
    @media only screen and (max-width: 767px) {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      padding: 25px 8px 15px;
      margin-bottom: 0;
      overflow: hidden;
    }

    .title-box {
      @media only screen and (max-width: 767px) {
        display: none;
      }

      .title {
        background: $black;
        color: $white;
        padding: 0.313rem 0.5rem;
        gap: 4px;
        border-radius: 0.125rem;
        margin-right: 0.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 12px;
          padding: 5px 8px;
          border-radius: 2px;
          margin-right: 10px;
        }
      }

      span {
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      .item-text {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.063rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }

        .icon {
          font-size: 1rem !important;
          margin-right: 0.438rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px !important;
            margin-right: 7px;
          }
        }

        b {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.188rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }

    .info-box {
      margin: 0.875rem;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      @media only screen and (max-width: 767px) {
        display: none;
      }

      span {
        font-size: 1rem;
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }

        .icon {
          font-size: 1rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 16px !important;
          }
        }

        .item-text {
          white-space: nowrap;

          .superior-text {
            font-size: 0.625rem;
            @media only screen and (max-width: 767px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .content-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 1.125rem;
      @media only screen and (max-width: 767px) {
        margin-top: 0;
      }

      .space-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 0.625rem;
        margin: 0 -0.125rem;
        @media only screen and (max-width: 767px) {
          margin: 0 3px;
          margin-bottom: 0;
          justify-content: space-between;
        }

        .column-space-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 0.125rem;
          flex: 1;
          @media only screen and (max-width: 767px) {
            padding: 0 2px;
          }

          .item-text {
            display: block;
            text-align: center;
            width: 100%;
            background: #f5f7f8;
            padding: 0.75rem;
            @media only screen and (max-width: 767px) {
              padding: 12px;
            }

            .icon {
              font-size: 1rem !important;
              margin-right: 0.313rem;
              @media only screen and (max-width: 767px) {
                font-size: 16px !important;
                margin-right: 5px;
              }
            }
          }
        }
      }

      .space-box {
        display: flex;
        align-items: center;
        margin: 0.5rem 0.625rem;
        @media only screen and (max-width: 767px) {
          margin: 8px 0;
        }

        .space-image-box {
          img {
            width: 5.938rem;
            @media only screen and (max-width: 767px) {
              width: 95px;
            }
          }
        }

        .space-content-box {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          margin-left: 0.625rem;
          @media only screen and (max-width: 767px) {
            margin-left: 10px;
          }

          .space-description {
            text-align: justify;
            @media only screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 14px;
            }
          }

          .space-price {
            margin-top: 0.625rem;
            text-align: justify;
            @media only screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 14px;
              margin-top: 10px;
            }
          }
        }
      }

      .content-buttons {
        display: flex;
        justify-content: space-between;

        .button-atom {
          padding: 0.25rem 0.45rem;
          height: 2.75rem;
          text-transform: none;
          flex: 1;
          margin: 0.125rem 0;
          @media only screen and (max-width: 767px) {
            padding: 4px 7px;
            height: 40px;
            margin: 0 5px;
          }

          span {
            text-transform: none;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }

        .request-offer-btn {
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;

          &:hover {
            background: #23cd9a;

            span {
              color: $white;
            }
          }

          &.button-green {
            background: #23cd9a;

            .icon {
              display: none;
              margin-right: 0.5rem;
            }

            span {
              color: $white;
            }

            &:hover {
              background: $black;

              .icon {
                display: block;
                margin-right: 8px;
              }
            }
          }
        }

        .shortlist-btn {
          background: $white;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            color: $black !important;
            margin-right: 0.5rem;
            @media only screen and (max-width: 767px) {
              margin-right: 8px;
            }
          }

          span {
            color: $black;
          }

          &:hover {
            background: #23cd9a;

            .icon {
              color: $white !important;
            }

            span {
              color: $white;
            }
          }
        }

        .remove-shortlist-btn {
          background: $white;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;

          .icon {
            color: $black !important;
            margin-right: 0.5rem;
            @media only screen and (max-width: 767px) {
              margin-right: 8px;
            }
          }

          span {
            color: $black;
          }

          &:hover {
            background: #ef3075;

            .icon {
              color: $white !important;
            }

            span {
              color: $white;
            }
          }
        }
      }
    }
  }

  .surface-details {
    padding: 0.875rem;
    @media only screen and (max-width: 767px) {
      padding: 14px;
    }

    .title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.188rem;
      margin-bottom: 0.625rem;
      display: block;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .unit-wrapper {
      border-bottom: 1px solid #e9edef;
      padding: 0.125rem 0;
      margin: 0.313rem 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        padding: 2px 0;
        margin: 5px 0;
      }

      * {
        font-size: 1rem;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .unit-surface {
        font-weight: 500;
      }
    }
  }

  .floor-plan-button-wrapper {
    padding: 0 0.875rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 15px;
    }

    .floor-plan-button {
      span {
        color: #020202;
        text-transform: none;
      }

      background: #e2eaec;
      width: 100%;
      border: none;

      &:hover {
        background: #23cd9a;

        .icon {
          color: $white !important;
        }

        span {
          color: $white;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .organism-drawer {
      .drawer-content {
        display: none;
        padding: 0;
        top: initial;
        bottom: 117px;
        left: 50%;
        right: initial;
        transform: translateX(-50%);
        width: 334px;
        max-width: 95%;

        &.open {
          display: block;
          right: initial;
        }

        .drawer-content-wrapper {
          height: 55vh !important;
          overflow-y: auto;
          padding-bottom: 155px;
        }
      }

      .close-button {
        display: none;
      }
    }
  }
}
</style>
